import React ,{Fragment,useEffect,useContext}from 'react';
import './InboxNotification.scss';
import {NavLink,Link} from "react-router-dom";
//import Grid from '@material-ui/core/Grid';
//import { makeStyles } from '@material-ui/core/styles';
//import {Money,History,LocalAtm,AccountBalance} from '@material-ui/icons';
import {UseraccountContextApi} from "../../UseraccountContextApi";
//import {ArrowRightAlt} from '@material-ui/icons';

//import {MenuOpen} from '@material-ui/icons';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
//   InboxSidebarMenu:{
//     [theme.breakpoints.down('sm')]:{
//       display:'none'
//      },
//      [theme.breakpoints.up('md')]:{
//       display:'inline'
       
//      } 
//   },
// }));



    
const InboxSideMenu = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  //const Functions = MainState.Functions

  const {Appsettings} = Appstate
 // const {CurrencyFormat} = Functions
   const {themecolor1} = Appsettings

  //const classes = useStyles();



// const [ErrorMessage, setErrorMessage] = React.useState(null);

// const [DashboardInfor, setDashboardInfor] = React.useState({
//   title:'',
//   description:'',
//   icon:'',
// });

// const [ModalTitle, setModalTitle] = React.useState('');





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  



//0683300300 msangwa herbal kona ya nyamhongoro



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])









// const WithdrawReportMap = [0,1,2,3,4,5].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// })  



// const PendingTransactionMap= [0].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// }) 






return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="inbox-notification-wrapper">

<div className='inbox-sidemenu'>
    <div className='inbox-sidemenu-compose' style={{backgroundColor:`${themecolor1}`}}>
      <NavLink to='#'>Compose</NavLink>
    </div>
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Folders</h3>
      <div className="card-tools">
        <button type="button" className="btn btn-tool" data-card-widget="collapse">
          <i className="fas fa-minus" />
        </button>
      </div>
    </div>
    <div className="card-body p-0">
      <ul className="nav nav-pills flex-column">
        <li className="nav-item active">
          <Link to="#" className="nav-link">
            <i className="fas fa-inbox" /> Inbox
            <span className="badge bg-primary float-right"></span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="#" className="nav-link">
            <i className="far fa-envelope" /> Sent
          </Link>
        </li>
        <li className="nav-item">
          <Link to="#" className="nav-link">
            <i className="far fa-file-alt" /> Drafts
          </Link>
        </li>
        <li className="nav-item">
          <Link to="#" className="nav-link">
            <i className="fas fa-filter" /> Junk
            <span className="badge bg-warning float-right">65</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="#" className="nav-link">
            <i className="far fa-trash-alt" /> Trash
          </Link>
        </li>
      </ul>
    </div>
    {/* /.card-body */}
  </div>
  {/* /.card */}
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Labels</h3>
      <div className="card-tools">
        <button type="button" className="btn btn-tool" data-card-widget="collapse">
          <i className="fas fa-minus" />
        </button>
      </div>
    </div>
    <div className="card-body p-0">
      <ul className="nav nav-pills flex-column">
        <li className="nav-item">
          <a href="#none" className="nav-link">
            <i className="far fa-circle text-danger" />
            Important
          </a>
        </li>
        <li className="nav-item">
          <a href="#none" className="nav-link">
            <i className="far fa-circle text-warning" /> Promotions
          </a>
        </li>
        <li className="nav-item">
          <a href="#none" className="nav-link">
            <i className="far fa-circle text-primary" />
            Social
          </a>
        </li>
      </ul>
    </div>
    {/* /.card-body */}
  </div>

    </div>

    

</div>
</Fragment>
 
    
);


// InboxSideMenu.propTypes = {}
        
// InboxSideMenu.defaultProps ={}


}
export {InboxSideMenu};

