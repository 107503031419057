import React ,{Fragment,useEffect,useContext}from 'react';
import {useLocation,NavLink, Link} from 'react-router-dom';
import './BreadcumbNavigation.scss';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));



    
const BreadcumbNavigation = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User} = Appstate
  const {CurrencyFormat} = Functions
  const {themecolor1,themecolor2} = Appsettings

  const classes = useStyles();

  const Location  = useLocation()

  const {pathname} = Location
  var PathNameLocaion = pathname.split('/')

  const [currentTab, setcurrentTab] = React.useState({currentTab:'',titleTab:''});

useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  

  if(PathNameLocaion[2]==='dashboard'){

    setcurrentTab({currentTab:'Dashboard',titleTab:'Dashboard metric'})
  }
  if(PathNameLocaion[2]==='geneology'){

    setcurrentTab({currentTab:'Community',titleTab:'Community Tree'})
  }
  if(PathNameLocaion[2]==='transaction'){

    setcurrentTab({currentTab:'Commission',titleTab:'Transaction Account'})
  }
  if(PathNameLocaion[2]==='loanapplication'){

    setcurrentTab({currentTab:'Loan',titleTab:'Loan application'})
  }
  if(PathNameLocaion[2]==='inbox'){

    setcurrentTab({currentTab:'Inbox',titleTab:'Inbox messages'})
  }
  if(PathNameLocaion[2]==='profile'){

    setcurrentTab({currentTab:'Profile',titleTab:'Profile settings'})
  }


return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[pathname])



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}



return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="breadcumb-wrapper">
            {/* Breadcumb navigation */}
        <Breadcrumbs aria-label="breadcrumb">
         <Link to='/account/dashboard' onClick={handleClick} className={classes.link}>
      <HomeIcon className={classes.icon} />
        Home
      </Link>
      <Link
        color="inherit"
        href="/getting-started/installation/"
        onClick={handleClick}
        className={classes.link}
      >
      <WhatshotIcon className={classes.icon} />
        {currentTab.currentTab}
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        <GrainIcon className={classes.icon} />
        {currentTab.titleTab}
      </Typography>
    </Breadcrumbs>

</div>
</Fragment>
 
    
);


// BreadcumbNavigation.propTypes = {}
        
// BreadcumbNavigation.defaultProps ={}


}
export {BreadcumbNavigation};

