import React ,{Fragment,useEffect}from 'react';
import {useHistory} from "react-router-dom";
import './UserRegistrationForm.scss';
//import { makeStyles } from '@material-ui/core/styles';
import {ThemeProvider,createMuiTheme} from "@material-ui/core";
import {Grid,Divider,Typography,Button,ButtonGroup} from '@material-ui/core';   
import {TanzaniaRegionsList,TanzaniaDistrictList} from "../../../../TanzaniaRegionList";
import {CheckCircle,CancelPresentation} from '@material-ui/icons';
import {HttpServer_UserRegistration,HttpServer_FetchUserProfileInformation} from "../../HttpcallUserApp";
//import $ from 'jquery';
import TextField from '@material-ui/core/TextField';

import {ClipLoader,ScaleLoader} from "react-spinners";





// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));


    
const UserRegistrationForm = ({props}) => { //--------------MAIN FUNCTION----------------

//const classes = useStyles();


const [Formsettings, setFormsettings] = React.useState({
  logo:'brand-logo.png',
  themecolor1:'#FBB117',
  themecolor2:'#CA226B',
});



const theme = createMuiTheme({

  palette:{
    primary:{
      main:`${Formsettings.themecolor1}`
    },
    secondary:{
      main:`${Formsettings.themecolor2}`
    },
  },
  typography:{
    //fontFamily:'Comic Sans Ms',
    body1:{
      fontSize:'1.1rem',
    
    },
    // h1:{
    //   color:'red'
    // }
  },
  shape:{
    borderRadius:0,
  },
  //spacing:8,
  overrides:{
    MuiButton:{
      root:{
        textTransform:'none',
        
      }
    },

  },
  props:{
    MuiButton:{
      // disableRipple:true,
      // variant:'text'
    },
    MuiButtonGroup:{
      // disableRipple:true,
      // variant:'outlined'
    },
    MuiTextField:{
      InputLabelProps:{
        shrink:true
      }
    }
    
  }

})

const History = useHistory()


const [DemoUsernameCount, setDemoUsernameCount] = React.useState(1);


const [RegistrationForm, setRegistrationForm] = React.useState({
  username_email:'',
  password:'',
  confirm_password:'',
  first_name:'',
  last_name:'',
  middle_name:'',
  sponsor_id:'',
  primary_email:'',
  phone_number1:'',
  phone_number2:'',
  region_name:'0',
  district_name:'0',
  ward_name:'',
  voucher_pin:'',
  inheritance_first_name:'',
  inheritance_middle_name:'',
  inheritance_last_name:'',
  inheritance_relationship:'',
  inheritance_phone_number:'' ,
  inheritance_bank_name:'',
  inheritance_bank_number:'',
  inheritance_bank_account_name:''


  }
  );  


const [FormValidationError, setFormValidationError] = React.useState({
    FirstNameError:false,
    LastNameError:false,
    MiddleNameError:false,
    PhoneNumber1Error:false,
    PhoneNumber2Error:false,
    RegionNameError:false,
    DistrictNameError:false,
    WardNameError:false,
    SponsoridError:false,
    VoucherError:false,
    PrimaryEmailError:false,
    UsernameEmailError:false,
    PasswordError:false,
    PasswordMatchError:false,
    InheritanceFirstnameError:false,
    InheritanceLastNameError:false,
    InheritanceMiddlenameError:false,
    InheritanceBanknameError:false,
    InheritanceBankAccountNameError:false,
    InheritanceBankNumberError:false,
    InheritanceRelationshipError:false,
    InheritancePhoneNumberError:false,
    }
);

const [ServerStatusResponse, setServerStatusResponse] = React.useState(null);
 
const [ServerMessage, setServerMessage] = React.useState(null);

// const [ServerInnerStatus, setServerInnerStatus] = React.useState(null);

const [RegistrationLoading, setRegistrationLoading] = React.useState(false);



const [GenerationMemberList, setGenerationMemberList] = React.useState([]);

 
const [ValidationErrorMessage, setValidationErrorMessage] = React.useState(null);

const [ServerIpAddress, setServerIpAddress] = React.useState(()=>{
  if(process.env.NODE_ENV==='production'){
    return "/";
   }else{ 
     return  "http://127.0.0.1:8000/";
    }
});





useEffect(()=>{//------------COMPONENT DID MOUNT--------------------------------------
  
 

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])



const OnchangeFirstName =(event)=>{
  setValidationErrorMessage(null)

  setRegistrationForm({...RegistrationForm,first_name:event.target.value})

  if(event.target.value.length > 2){

    setFormValidationError({...FormValidationError,FirstNameError:false})

  }
}


const OnchangeLastName =(event)=>{

  setValidationErrorMessage(null)
setRegistrationForm({...RegistrationForm,last_name:event.target.value})

if(event.target.value.length > 2){

setFormValidationError({...FormValidationError,LastNameError:false})

}
}


const OnchangeMiddleName =(event)=>{

  setValidationErrorMessage(null)  
setRegistrationForm({...RegistrationForm,middle_name:event.target.value})

if(event.target.value.length > 2){

setFormValidationError({...FormValidationError,SurNameError:false})

}
}





const OnchangeVoucher =(event)=>{

  const {value} = event.target
  setValidationErrorMessage(null)
  
  setRegistrationForm({...RegistrationForm,voucher_pin:value})
  
  if(value.length >= 14){
  
    setFormValidationError({...FormValidationError,InheritanceFirstnameError:false})
  
  }
}

   

const OnchangeSponsorid =(event)=>{

  const {value} = event.target
  setValidationErrorMessage(null)
  
  setRegistrationForm({...RegistrationForm,sponsor_id:value})
  
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,SponsoridError:false})
  
  }
} 


const OnchangeUsernameEmail =(event)=>{
  setValidationErrorMessage(null)
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,username_email:value})
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,SponsoridError:false})
  
  }
} 

const OnchangePasword =(event)=>{
  
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,password:value})
  setValidationErrorMessage(null)

  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,PasswordError:false})
  
  }
} 

const OnchangeConfirmPassword =(event)=>{
  
  const {value} = event.target
  setValidationErrorMessage(null)
  setRegistrationForm({...RegistrationForm,confirm_password:value})
  
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,PasswordMatchError:false})
  
  }
} 





function validateUsername(username) {
  // Define the criteria for validation
  const minLength = 6;
  const maxLength = 30;
  const regex = /^[a-zA-Z][a-zA-Z0-9@_]*$/;

  // Check if the username is within the length limits
  if (username.length < minLength || username.length > maxLength) {
      return {
          isValid: false,
          message: `Username must be between ${minLength} and ${maxLength} characters long.`
      };
  }

  // Check if the username follows the pattern
  if (!regex.test(username)) {
      return {
          isValid: false,
          message: 'Username must start with a letter and can only contain letters, numbers, "@", and "_".'
      };
  }

  // If all checks pass, the username is valid
  return {
      isValid: true,
      message: 'Username is valid.'
  };
}





const FormValidation = () =>{
  return new Promise(function(resolve,reject){
    
    var ValidateUsername = validateUsername(RegistrationForm.username_email)
    console.log(ValidateUsername)
   if(ValidateUsername.isValid===false){
    const {message} = ValidateUsername
   
    setFormValidationError({...FormValidationError,UsernameEmailError:true})
    setValidationErrorMessage(message)
    return reject(message)

   }else if(RegistrationForm.password.length<=4){

    setValidationErrorMessage('password is too short')

   }else if(RegistrationForm.confirm_password !== RegistrationForm.password){

    setValidationErrorMessage('password do not match')

   }else if(RegistrationForm.first_name.length < 2){

    setValidationErrorMessage('fill in valid first_name field')
   }
   else if(RegistrationForm.last_name.length  < 2){

     setValidationErrorMessage('fill in a valid last_name')

   }
   else if(RegistrationForm.sponsor_id.length < 6){

    setValidationErrorMessage('fill in a valid sponsor_id')

   }else if(RegistrationForm.voucher_pin.length < 14){

    setValidationErrorMessage('fill in a valid voucher_pin')

   }else{

    return resolve('successfuly validation.')
   }


  })
}






const OnsubmitRegistration = ()=>{

//---validate form first
FormValidation().then(function(JsonResponse){

  setServerStatusResponse(null)
  var FormPayload = RegistrationForm
  setRegistrationLoading(true)
  HttpServer_UserRegistration(ServerIpAddress,FormPayload).then(function(JsonResponse){
  const {status,Jsondata} = JsonResponse
  setServerStatusResponse(status)
  setRegistrationLoading(false)
  console.log(JsonResponse)

  if(status===200){

    const {message} = Jsondata
    // const {inner_status} = Jsondata 

    // setServerInnerStatus(inner_status)


   
    setServerMessage(message)

    

   }else if(status===202){

    const {data,message} = Jsondata
    setGenerationMemberList(data)
    setServerStatusResponse(status)
    setServerMessage(message)

   }else{
   
    const {message} = Jsondata
    setServerStatusResponse(status)
    setServerMessage(message)
  }

}).catch(function(ErrorResponse){

     setRegistrationLoading(false)
     setServerStatusResponse('offline')
})

}).catch(function(ValidationErrorMesage){

  

})


}


const OnchangeGenerationSelection =(event)=>{
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,sponsor_id:value})
} 


const GenerationMemberListMap = GenerationMemberList.map(function(object,index){
  return(
     <React.Fragment key={index}>
           <Grid item xs={12} sm={6}>
           <div className='generation-selection-wrapper'>
             <div className='generation-profile-circle' style={{border:`4px solid ${Formsettings.themecolor2}`}}><img src='profile_avatar.png' alt='profile'/></div>
               <ul>
                  <li>{object.first_name} {object.middle_name}</li>
                  <br />
                  <li>Community({object.downline_count})</li>
                </ul>

            <div className="icheck-success d-inline">
             <input onClick={OnchangeGenerationSelection} type="radio" name="r3" value={object.id}  id={`member${index}`}  />
              <label htmlFor={`member${index}`}>
              </label>
           </div>
          </div>
        </Grid>
     </React.Fragment>
     )
})  




const UserProfileInformation = ()=>{

    HttpServer_FetchUserProfileInformation('http://127.0.0.1:8000/',RegistrationForm.username_email).then(function(JsonResponse){
      
    console.log(JsonResponse)
  
  })
  
}
  



const NavigateLogin =()=>{

  History.replace({pathname:"/auth/login"})
}

  
  







return (   //--------RETURN HTML---------------------------
<Fragment>
<ThemeProvider theme={theme}>   

<div className="profile-wrapper">


<div className='registration-top-nav'></div>
<div className="card" id='registration-form-card'>
    <div className="card-body" style={{opacity:RegistrationLoading===true?'0.7':null}}> 
     
  
  
         
          <Grid container spacing={1}> 

          <Grid item xs={12}>
          <div className='company-logo-container'>
           <div class='company-logo-circle' style={{border:`4px solid ${Formsettings.themecolor1}`}}>
                    
                    <img src={Formsettings.logo} alt='brand logo'/>
              </div>
                
          </div>
             </Grid>

             <Grid item xs={12}>
               <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
              <Typography variant='caption'>WE WORKS & GROW TOGETHER</Typography>
              </div>
              </Grid>

             <Grid item xs={12}>
             <Typography variant='caption'>-----USER REGISTRATIONS PERSONAL INFORMATION----</Typography>
             </Grid>



            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Username OR Email"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeUsernameEmail} value={RegistrationForm.username_email}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                 
                  {RegistrationForm.username_email.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:<CancelPresentation style={{color:'#c24823'}} fontSize="default" className="validation-error"/>}
             
              </div>
              </Grid>
              <Grid item xs={10}>
    
              </Grid>
           </Grid>
           <Divider />
           <br/>

           <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Password"
              type='password'
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePasword} value={RegistrationForm.password}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">

                 {RegistrationForm.password.length >= 4?<CheckCircle fontSize="default" className="validation-success"/>:<CancelPresentation style={{color:'#c24823'}}  fontSize="default" className="validation-error"/>}


              </div>
              </Grid>
           </Grid>
           <Divider />
           <br/>

           <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Confirm Password"
              type='password'
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeConfirmPassword} value={RegistrationForm.confirm_password}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                 
                 {RegistrationForm.confirm_password.length >= 4?<CheckCircle fontSize="default" className="validation-success"/>:<CancelPresentation style={{color:'#c24823'}} fontSize="default" className="validation-error"/>}
             
              </div>
              </Grid>
              <Grid item xs={10}>
           
              </Grid>
           </Grid>
           <Divider />
           <br/>

          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
               <TextField
              id="filled-required"
              label="First Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeFirstName} value={RegistrationForm.first_name}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                 
                {RegistrationForm.first_name.length > 2?<CheckCircle fontSize="default" className="validation-success"/>:<CancelPresentation style={{color:'#c24823'}} fontSize="default" className="validation-error"/>}  
              
              </div>
              </Grid>

              <Grid item xs={10}>

             </Grid>


           </Grid>
           <Divider />
           <br/>

           
          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Middle Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeMiddleName} value={RegistrationForm.middle_name}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                 
                  {RegistrationForm.middle_name.length > 2?<CheckCircle fontSize="default" className="validation-success"/>:<CancelPresentation style={{color:'#c24823'}} fontSize="default" className="validation-error"/>}
              
              </div>
              </Grid>
           </Grid>
            <Divider />
            <br/>

            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="Last Name"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeLastName} value={RegistrationForm.last_name}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                 
                  
              {RegistrationForm.last_name.length >= 2?<CheckCircle fontSize="default" className="validation-success"/>:<CancelPresentation style={{color:'#c24823'}} fontSize="default" className="validation-error"/>}

              </div>
              </Grid>

              <Grid item xs={10}>
    
             </Grid>


           </Grid>
            <Divider />
            <br/>
            {GenerationMemberList.length===0?
            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="SPONSOR ID"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'

              onChange={OnchangeSponsorid} value={RegistrationForm.sponsor_id}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

             <Grid item xs={1}>
              <div className="registration-validation">
                  
                {RegistrationForm.sponsor_id.length >= 4?<CheckCircle fontSize="default" className="validation-success"/>:<CancelPresentation style={{color:'#c24823'}} fontSize="default" className="validation-error"/>}
              
              </div>
              </Grid>

              <Grid item xs={10}>
    
             </Grid>

           </Grid>
             :null}
            <Divider />
            <br/>

            <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              label="VOUCHER PIN"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeVoucher} value={RegistrationForm.voucher_pin}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  
              {RegistrationForm.voucher_pin.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:<CancelPresentation style={{color:'#c24823'}} fontSize="default" className="validation-error"/>}
              

              </div>
              </Grid>

              <Grid item xs={10}>
                {ValidationErrorMessage !==null?
                <div className="validation-error-message">
                <span>{ValidationErrorMessage}</span>
                </div>
                :null}
             </Grid>


           </Grid>
            <Divider />
            <br/>

            {GenerationMemberList.length > 0 ?
             <div className="card card-success">
               <div className="card-header" id='full-network-title'>
               <h4 className="card-title">YOUR SPONSOR NETWORK IF FULL  </h4>
              <br />
             <h6 className="card-title">(please select descendants community to join to.)</h6>
             </div>
              <div className="card-body">
               <Grid container spacing={1}> 

               {GenerationMemberListMap}

      
             </Grid>
            </div>
           </div>
           :null}
   
               {/*----- SERVER MESSAGE ------------*/}
               {ServerStatusResponse=== 202 && GenerationMemberList.length===0?
                <div className="alert alert-warning alert-dismissible">
                 {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                   <h5><i className="icon fas fa-exclamation-triangle" /> Alert!</h5>
                    {ServerMessage}
                </div>
                :null}

                 {/*----- SERVER ERROR ------------*/}
               {ServerStatusResponse > 202 && GenerationMemberList.length===0?
                <div className="alert alert-warning alert-dismissible">
                 {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                   <h5><i className="icon fas fa-exclamation-triangle" /> Alert!</h5>
                    SERVER ERROR {ServerStatusResponse}{ServerMessage}
                </div>
                :null}
                
                {ServerStatusResponse===200?
               <div className="alert alert-success alert-dismissible">
                {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                 <h5><i className="icon fas fa-check" /> Alert!</h5>
                   {ServerMessage}
                </div>
                :null}


                      
                {ServerStatusResponse==='offline'?
               <div className="alert alert-warning alert-dismissible">
                {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button> */}
                 <h5><i className="icon fas fa-check" /> Alert!</h5>
                   COULD NOT CONNECT !
                </div>
                :null}

            

           </div>
               <div className='registration-loader-wrapper'>
                <ScaleLoader speedMultiplier={2} loading={RegistrationLoading} height={50} width={10} color={Formsettings.themecolor1} />
                </div>
                


     
               <ButtonGroup fullWidth={true}  color='secondary' aria-label="small outlined button group">
                 <Button disabled={RegistrationLoading===true?true:false}   onClick={OnsubmitRegistration} type="button"  data-bs-dismiss="modal"  color='primary'>Register</Button>
                 <Button disabled={RegistrationLoading===true?true:false}   onClick={NavigateLogin} type="button"  data-bs-dismiss="modal"  color='primary'>Login</Button>
               
               </ButtonGroup>

               {/* <ButtonGroup fullWidth={true}  color='secondary' aria-label="small outlined button group">
                 <Button  onClick={UserProfileInformation} type="button"  data-bs-dismiss="modal"  color='primary'>PROFILE INFO</Button>
               </ButtonGroup> */}

               <div className="registration-form-footer">
              
                </div>
      </div>




</div>

</ThemeProvider>
</Fragment>
 
    
);


// UserRegistrationForm.propTypes = {}
        
// UserRegistrationForm.defaultProps ={}


}
export {UserRegistrationForm};

