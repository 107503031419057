import React ,{Fragment,useEffect}from 'react';
import PropTypes from 'prop-types';
import './Subcomponents.scss'
import {Link,useLocation,useHistory} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';


import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link as UiLink}from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';







const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },

  },

  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));



const  LinearProgressLoading =({Loading=false,color})=>{
  const classes = useStyles();

  return (
    <div className={classes.root}>
    
      {Loading===true?
      <LinearProgress color="secondary" style={{height:10}}/>
      :null}
    </div>
  );
}



const  WarningAlert = ({props,message}) => {

return (
  <Fragment>

    <div class="alert alert-warning alert-dismissible fade show" role="alert">
<strong>{message}</strong> 
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
</Fragment>
);


WarningAlert.propTypes = {
message:PropTypes.string.isRequired 
}
WarningAlert.defaultProps ={ }
    

}






const JsonCleaner = (json)=>{
  var cleaned =  JSON.parse(json.replace(/'/g,'"'))
    
    return cleaned
  }

const  LoadingSpinner = ({props,status,loading,size=25,color="#00FF00"}) => {



  useEffect(()=>{




  },[loading])

  if(loading){

    return (
      <Fragment>
     
    </Fragment>
    );

  }else{

    return null
  }

 
  
  
  LoadingSpinner.propTypes = {
  message:PropTypes.string.isRequired,
  status:PropTypes.number.isRequired,
  loading:PropTypes.bool.isRequired
  }
  LoadingSpinner.defaultProps ={
  loading:false

   }
      
  
}

const ImageSizeValidator = (size,fileType) =>{
  var format = ["image/png","image/gif","image/jpeg"]
  var count =0
  var countImange = 0
  var Mb = size
  var validSize = 4//3MB
  var respond = {validSize:false,validType:false}

 for (count = 0; count < 2 ; count++){
    Mb  /= 1024

  }

if(Mb <= validSize){
respond['validSize'] = true


}else{

  respond['validSize'] = false
  
}


for(countImange ; countImange < format.length; countImange++){

  if(fileType=== format[countImange]){

    respond['validType'] = true
  }
    

}

return respond
} 


const ObjectCounter = (object) =>{
  var count = 0
  
  for(var key in object){
  
    if(object.hasOwnProperty(key)){
  
      count +=1
    }
  }
  return count
}


const  ProfileIcon = ({profile}) => {



useEffect(()=>{




},[profile])

 
return ( //-----------RETURN HTML

<Fragment>
<div id="profile-icon"></div>





</Fragment>
)



 
  
  
ProfileIcon.propTypes = {
profile:PropTypes.string.isRequired
}
ProfileIcon.defaultProps ={


}
      
  
}














export{
LinearProgressLoading,  
WarningAlert,
JsonCleaner,
LoadingSpinner,
ImageSizeValidator,
ObjectCounter,
ProfileIcon,

}


