import React ,{Fragment,useEffect}from 'react';
import {Route,Switch} from 'react-router-dom';
import {Dashboard} from './components/Dashboard/Dashboard';
import {Generations} from "./components/Generations/Generations";
import {Transaction} from "./components/Myaccount/Transaction";
import {LoanApplication} from "./components/Myaccount/LoanApplication";
import {InboxNotification} from "./components/InboxNotification/InboxNotification";
import {InboxMessageView} from "./components/InboxMessageView/InboxMessageView";
import {Profile} from './components/Profile/Profile';


const  NavigationUseraccount = (props) => {









useEffect(()=>{//COMPONENT DID MOUNT






 
    


},[])














return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>
<Switch>

<Route exact={true}  path='/' render={(props)=>(<Dashboard />)} /> 

<Route exact={true} path='/dashboard' render={(props)=>(<Dashboard />)} /> 

<Route exact={true} path='/geneology' render={(props)=>(<Generations />)} /> 

<Route exact={true} path='/transaction' render={(props)=>(<Transaction />)} /> 

<Route exact={true} path='/loanapplication' render={(props)=>(<LoanApplication />)} /> 

<Route exact={true} path='/inbox' render={(props)=>(<InboxNotification />)} /> 

<Route exact={true} path='/inbox/:InboxType' render={(props)=>(<InboxNotification />)} /> 

<Route exact={true} path='/inbox/reads/:MessageType' render={(props)=>(<InboxMessageView />)} /> 

<Route exact={true} path='/profile' render={(props)=>(<Profile />)} /> 



<Route render={(props)=>(<h1>PAGE NOT FOUND !</h1> )}  /> 

</Switch>

</Fragment>
);
}
export {NavigationUseraccount};

