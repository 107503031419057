import React ,{Fragment,useEffect} from 'react';
// import PropTypes from 'prop-types';
// import $ from 'jquery'



var regions ={
    DarEsSalaam:true,
    Dodoma:false,
    Mwanza:false,
    Mbeya:true,
    Arusha:false,
    Kilimanjaro:false,
    Pwani:false,
    Tanga:false,
    Morogoro:false,
    Pemba:false,
    Iringa:false,
    Kigoma:false,
    Lindi:false,
    Mara:false,
    Unguja:false,
    Mtwara:false,
    Njombe:false,
    Rukwa:false,
    Ruvuma:false,
    Singida:false,
    Shinyanga:false,
    Songwe:false,
    Geita:false,
    Katavi:false,
    Manyara:false,
    Simiyu:false,
    Tabora:false
}

const  TanzaniaRegionsList = ({props}) => {
return (
<Fragment>

  <option style={{fontSize: 20}} value="Dar_es_salaam">Dar Es Salaam</option>
  <option style={{fontSize: 20}} value="Dodoma">Dodoma</option>
  <option style={{fontSize: 20}} value="Mwanza">Mwanza</option>
  <option style={{fontSize: 20}} value="Mbeya">Mbeya</option>
  <option style={{fontSize: 20}} value="Arusha">Arusha</option>
  <option style={{fontSize: 20}} value="Kilimanjaro">Kilimanjaro</option>
  <option style={{fontSize: 20}} value="Pwani">Pwani</option>
  <option style={{fontSize: 20}} value="Tanga">Tanga</option>
  <option style={{fontSize: 20}} value="Morogoro">Morogoro</option>
  <option style={{fontSize: 20}} value="Pemba">Pemba</option>
  <option style={{fontSize: 20}} value="Iringa">Iringa</option>
  <option style={{fontSize: 20}} value="Kigoma">Kigoma</option>
  <option style={{fontSize: 20}} value="Lindi">Lindi</option>
  <option style={{fontSize: 20}} value="Mara">Mara</option>
  <option style={{fontSize: 20}} value="Unguja">Unguja</option>
  <option style={{fontSize: 20}} value="Mtwara">Mtwara</option>
  <option style={{fontSize: 20}} value="Njombe">Njombe</option>
  <option style={{fontSize: 20}} value="Rukwa">Rukwa</option>
  <option style={{fontSize: 20}} value="Ruvuma">Ruvuma</option>
  <option style={{fontSize: 20}} value="Singida">Singida</option>
  <option style={{fontSize: 20}} value="Shinyanga">Shinyanga</option>
  <option style={{fontSize: 20}} value="Songwe">Songwe</option>
  <option style={{fontSize: 20}} value="Geita">Geita</option>
  <option style={{fontSize: 20}} value="Katavi">Katavi</option>
  <option style={{fontSize: 20}} value="Kigoma">Kigoma</option>
  <option style={{fontSize: 20}} value="Manyara">Manyara</option>
  <option style={{fontSize: 20}} value="Simiyu">Simiyu</option>
  <option style={{fontSize: 20}} value="Tabora">Tabora</option>


</Fragment>    
);

}


const   DarEsSalaamDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Kinondoni">Kinondoni</option>
  <option style={{fontSize: 20}} value="Ilala">Ilala</option>
  <option style={{fontSize: 20}} value="Ubungo">Ubungo</option>
  <option style={{fontSize: 20}} value="Kigamboni">Kigamboni</option>
  <option style={{fontSize: 20}} value="Temeke">Temeke</option>

  </Fragment>    
    );
    
}

const   DodomaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Dodoma Urban">Dodoma Urban</option>
  <option style={{fontSize: 20}} value="Kondoa">Kondoa</option>
  <option style={{fontSize: 20}} value="Chemba">Chemba</option>
  <option style={{fontSize: 20}} value="Kongwa">Kongwa</option>
  <option style={{fontSize: 20}} value="Mpwapwa">Mpwapwa</option>
  <option style={{fontSize: 20}} value="Bahi">Bahi</option>
  <option style={{fontSize: 20}} value="Chamwino">Chamwino</option>


  </Fragment>    
    );
    
}

const   MwanzaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Ilemela">Ilemela</option>
  <option style={{fontSize: 20}} value="Kiwimba">Kiwimba</option>
  <option style={{fontSize: 20}} value="Magu">Magu</option>
  <option style={{fontSize: 20}} value="Misungwi">Misungwi</option>
  <option style={{fontSize: 20}} value="Nyamagana">Nyamagana</option>
  <option style={{fontSize: 20}} value="Sengerema">Sengerema</option>
  <option style={{fontSize: 20}} value="Ukerewe">Ukerewe</option>
  </Fragment>    
    );
}

const   MbeyaDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 20}} value="Chunya">Chunya</option>
  <option style={{fontSize: 20}} value="Ileje">Ileje</option>
  <option style={{fontSize: 20}} value="Kyela">Kyela</option>
  <option style={{fontSize: 20}} value="Mbarali">Mbarali</option>
  <option style={{fontSize: 20}} value="Mbeya Urban">Mbeya Urban</option>
  <option style={{fontSize: 20}} value="Mbozi">Mbozi</option>
  <option style={{fontSize: 20}} value="Momba">Momba</option>
  <option style={{fontSize: 20}} value="Rungwe">Rungwe</option>


  </Fragment>    
    );
}

const   ArushaDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 20}} value="Arusha City">Arusha City</option>
  <option style={{fontSize: 20}} value="Arusha Rural District (in Arumeru) ">Arusha Rural District (in Arumeru) </option>
  <option style={{fontSize: 20}} value="Karatu">Karatu</option>
  <option style={{fontSize: 20}} value="Longido">Longido</option>
  <option style={{fontSize: 20}} value="Meru (in Arumeru)">Meru (in Arumeru)</option>
  <option style={{fontSize: 20}} value="Monduli">Monduli</option>
  <option style={{fontSize: 20}} value="Ngorongoro">Ngorongoro</option>

  </Fragment>    
    );
}


const   KilimanjaroDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Hai">Hai</option>
  <option style={{fontSize: 20}} value="Moshi Urban">Moshi Urban</option>
  <option style={{fontSize: 20}} value="Moshi Rural">Moshi Rural</option>
  <option style={{fontSize: 20}} value="Mwanga">Mwanga</option>
  <option style={{fontSize: 20}} value="Rombo">Rombo</option>
  <option style={{fontSize: 20}} value="Same">Same</option>
  <option style={{fontSize: 20}} value="Siha">Siha</option>

  </Fragment>    
    );
}

const   PwaniDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Bagamoyo">Bagamoyo</option>
  <option style={{fontSize: 20}} value="Kibaha">Kibaha</option>
  <option style={{fontSize: 20}} value="Kisarawe">Kisarawe</option>
  <option style={{fontSize: 20}} value="Mafia">Mafia</option>
  <option style={{fontSize: 20}} value="Mkuranga">Mkuranga</option>
  <option style={{fontSize: 20}} value="Rufiji">Rufiji</option>

  </Fragment>    
    );
}

const   TangaDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 20}} value="Handeni Rural">Handeni Rural</option>
  <option style={{fontSize: 20}} value="Handeni Urban">Handeni Urban</option>
  <option style={{fontSize: 20}} value="Kilindi">Kilindi</option>
  <option style={{fontSize: 20}} value="Korogwe Rural">Korogwe Rural</option>
  <option style={{fontSize: 20}} value="Korogwe Urban">Korogwe Urban</option>
  <option style={{fontSize: 20}} value="Lushoto">Lushoto</option>
  <option style={{fontSize: 20}} value="Muheza">Muheza</option>
  <option style={{fontSize: 20}} value="Mkinga">Mkinga</option>
  <option style={{fontSize: 20}} value="Pangani">Pangani</option>
  <option style={{fontSize: 20}} value="Tanga">Tanga</option>

</Fragment>    
    );
}

const   MorogoroDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Gairo">Gairo</option>
  <option style={{fontSize: 20}} value="Kilombero">Kilombero</option>
  <option style={{fontSize: 20}} value="Kilosa">Kilosa</option>
  <option style={{fontSize: 20}} value="Morogoro Urban">Morogoro Urban</option>
  <option style={{fontSize: 20}} value="Mvomero">Mvomero</option>
  <option style={{fontSize: 20}} value="Ulanga">Ulanga</option>

  </Fragment>    
    );
}

const   PembaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Chake Chake">Chake Chake</option>
  <option style={{fontSize: 20}} value="Mkoani">Mkoani</option>
  <option style={{fontSize: 20}} value="Micheweni">Micheweni</option>
  <option style={{fontSize: 20}} value="Wete">Wete</option>

  </Fragment>    
    );
}

const   IringaDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 20}} value="Iringa District Council">Iringa District Council</option>
  <option style={{fontSize: 20}} value="Kilolo District Council">Kilolo District Council</option>
  <option style={{fontSize: 20}} value="Mufindi District Council">Mufindi District Council</option>
  <option style={{fontSize: 20}} value="Iringa Municipal Council">Iringa Municipal Council</option>
  <option style={{fontSize: 20}} value="Mafinga Town Council">Mafinga Town Council</option>

</Fragment>    
    );
}

const   KigomaDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 20}} value="Buhigwe">Buhigwe</option>
  <option style={{fontSize: 20}} value="Kakonko">Kakonko</option>
  <option style={{fontSize: 20}} value="Kasulu Rural">Kasulu Rural</option>
  <option style={{fontSize: 20}} value="Kasulu Urban">Kasulu Urban</option>
  <option style={{fontSize: 20}} value="Kibondo">Kibondo</option>
  <option style={{fontSize: 20}} value="Kigoma Rural">Kigoma Rural</option>
  <option style={{fontSize: 20}} value="Kigoma Urban">Kigoma Urban</option>
  <option style={{fontSize: 20}} value="Uvinza">Uvinza</option>

</Fragment>    
    );
}

const   LindiDistricts = ({props}) => {
    return (
<Fragment>

  <option style={{fontSize: 20}} value="Kilwa">Kilwa</option>
  <option style={{fontSize: 20}} value="Lindi District">Lindi District</option>
  <option style={{fontSize: 20}} value="Liwale">Liwale</option>
  <option style={{fontSize: 20}} value="Nachingwea">Nachingwea</option>
  <option style={{fontSize: 20}} value="Ruangwa">Ruangwa</option>


</Fragment>    
    );
}

const   MaraDistricts = ({props}) => {
    return (
<Fragment>

  <option style={{fontSize: 20}} value="Bunda">Bunda</option>
  <option style={{fontSize: 20}} value="Butiama">Butiama</option>
  <option style={{fontSize: 20}} value="Musoma Rural">Musoma Rural</option>
  <option style={{fontSize: 20}} value="Musoma Urban">Musoma Urban</option>
  <option style={{fontSize: 20}} value="Rorya">Rorya</option>
  <option style={{fontSize: 20}} value="Serengeti">Serengeti</option>
  <option style={{fontSize: 20}} value="Tarime">Tarime</option>


</Fragment>    
    );
}

const   UngujaDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 20}} value="Kaskazini A ">Kaskazini A </option>
  <option style={{fontSize: 20}} value="Kaskazini B">Kaskazini B</option>

</Fragment>    
    );
}

const   MtwaraDistricts = ({props}) => {
    return (
<Fragment>

  <option style={{fontSize: 20}} value="Masasi">Masasi</option>
  <option style={{fontSize: 20}} value="Mtwara District">Mtwara District</option>
  <option style={{fontSize: 20}} value="Nanyumbu">Nanyumbu</option>
  <option style={{fontSize: 20}} value="Newala">Newala</option>
  <option style={{fontSize: 20}} value="Tandahimba">Tandahimba</option>


</Fragment>    
    );
}

const   NjombeDistricts = ({props}) => {
    return (
<Fragment>
  <option style={{fontSize: 20}} value="Ludewa District">Ludewa District</option>
  <option style={{fontSize: 20}} value="Makambako Town Council">Makambako Town Council</option>
  <option style={{fontSize: 20}} value="Makete District">Makete District</option>
  <option style={{fontSize: 20}} value="Njombe District Council">Njombe District Council</option>
  <option style={{fontSize: 20}} value="Njombe Town Council">Njombe Town Council</option>
  <option style={{fontSize: 20}} value="Wangingombe">Wanging'ombe</option>


</Fragment>    
);
}

const   RukwaDistricts = ({props}) => {
    return (
<Fragment>

  <option style={{fontSize: 20}} value="Kalambo">Kalambo</option>
  <option style={{fontSize: 20}} value="Lyamba lya Mfipa">Lyamba lya Mfipa</option>
  <option style={{fontSize: 20}} value="Nkasi">Nkasi</option>
  <option style={{fontSize: 20}} value="Sumbawanga">Sumbawanga</option>


</Fragment>    
);
}

const   RuvumaDistricts = ({props}) => {
return (
<Fragment>

  <option style={{fontSize: 20}} value="Mbinga">Mbinga</option>
  <option style={{fontSize: 20}} value="Namtumbo">Namtumbo</option>
  <option style={{fontSize: 20}} value="Nyasa">Nyasa</option>
  <option style={{fontSize: 20}} value="Songea Municipal">Songea Municipal</option>
  <option style={{fontSize: 20}} value="Songea Rural">Songea Rural</option>
  <option style={{fontSize: 20}} value="Tunduru">Tunduru</option>


</Fragment>    
);
}

const   SingidaDistricts = ({props}) => {
return (
<Fragment>

  <option style={{fontSize: 20}} value="Iramba">Iramba</option>
  <option style={{fontSize: 20}} value="Ikungi">Ikungi</option>
  <option style={{fontSize: 20}} value="Manyoni">Manyoni</option>
  <option style={{fontSize: 20}} value="Mkalama">Mkalama</option>
  <option style={{fontSize: 20}} value="Singida District">Singida District</option>
  <option style={{fontSize: 20}} value="Singida Municipality">Singida Municipality</option>
  <option style={{fontSize: 20}} value="Itigi">Itigi</option>


</Fragment>    
);
}

const   ShinyangaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Kahama Rural">Kahama Rural</option>
  <option style={{fontSize: 20}} value="Kahama Urban">Kahama Urban</option>
  <option style={{fontSize: 20}} value="Kishapu">Kishapu</option>
  <option style={{fontSize: 20}} value="Shinyanga Rural">Shinyanga Rural</option>
  <option style={{fontSize: 20}} value="Shinyanga Urban">Shinyanga Urban</option>


    </Fragment>    
    );
}


const   SongweDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 20}} value="Ileje District">Ileje District</option>
  <option style={{fontSize: 20}} value="Mbozi District">Mbozi District</option>
  <option style={{fontSize: 20}} value="Momba District">Momba District</option>
  <option style={{fontSize: 20}} value="Songwe District">Songwe District</option>

 </Fragment>    
    );
}

const   GeitaDistricts = ({props}) => {
    return (
    <Fragment>
  <option style={{fontSize: 20}} value="Bukombe">Bukombe</option>
  <option style={{fontSize: 20}} value="Chato">Chato</option>
  <option style={{fontSize: 20}} value="Geita">Geita</option>
  <option style={{fontSize: 20}} value="Mbogwe">Mbogwe</option>
  <option style={{fontSize: 20}} value="Nyanghwale">Nyang'hwale</option>

   </Fragment>    
    );
}

const   KataviDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 20}} value="Nsimbo">Nsimbo</option>
  <option style={{fontSize: 20}} value="Mpanda">Mpanda</option>
  <option style={{fontSize: 20}} value="Mlele">Mlele</option>
  </Fragment>    
    );
}



const   ManyaraDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 20}} value="Babati Rural">Babati Rural</option>
  <option style={{fontSize: 20}} value="Babati Town">Babati Town</option>
  <option style={{fontSize: 20}} value="Kiteto">Kiteto</option>
  <option style={{fontSize: 20}} value="Hanang">Hanang</option>
  <option style={{fontSize: 20}} value="Mbulu">Mbulu</option>
  <option style={{fontSize: 20}} value="Simanjiro">Simanjiro</option>

  </Fragment>    
    );
}

const   SimiyuDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 20}} value="Bariadi">Bariadi</option>
  <option style={{fontSize: 20}} value="Busega">Busega</option>
  <option style={{fontSize: 20}} value="Itilima">Itilima</option>
  <option style={{fontSize: 20}} value="Maswa">Maswa</option>
  <option style={{fontSize: 20}} value="Meatu">Meatu</option>

  </Fragment>    
    );
}

const   TaboraDistricts = ({props}) => {
    return (
    <Fragment>

  <option style={{fontSize: 20}} value="Igunga">Igunga</option>
  <option style={{fontSize: 20}} value="Kaliua">Kaliua</option>
  <option style={{fontSize: 20}} value="Nzega">Nzega</option>
  <option style={{fontSize: 20}} value="Sikonge"> Sikonge</option>
  <option style={{fontSize: 20}} value="Tabora Municipal">Tabora Municipal</option>
  <option style={{fontSize: 20}} value="Urambo">Urambo</option>
  <option style={{fontSize: 20}} value="Uyui">Uyui</option>

  </Fragment>    
    );
}


const TanzaniaDistrictList = ({props,region}) => {


useEffect(()=>{




},[region])



if(region==="Dar_es_salaam"){
return (<DarEsSalaamDistricts />);
} 
else if(region==="Dodoma"){
    return (<DodomaDistricts />);
} 
else if(region==="Mwanza"){
    return (<MwanzaDistricts />);
}
else if(region==="Mbeya"){
    return (<MbeyaDistricts />);
}
else if(region==="Arusha"){
    return (<ArushaDistricts />);
}
else if(region==="Kilimanjaro"){
    return (<KilimanjaroDistricts />);
}
else if(region==="Pwani"){
    return (<PwaniDistricts />);
}
else if(region==="Tanga"){
    return (<TangaDistricts />);
}
else if(region==="Morogoro"){
    return (<MorogoroDistricts />);
}
else if(region==="Pemba"){
    return (<PembaDistricts />);
}
else if(region==="Iringa"){
    return (<IringaDistricts />);
}
else if(region==="Kigoma"){
    return (<KigomaDistricts />);
}
else if(region==="Lindi"){
    return (<LindiDistricts />);
}
else if(region==="Mara"){
    return (<MaraDistricts />);
}
else if(region==="Unguja"){
    return (<UngujaDistricts />);
}
else if(region==="Mtwara"){
    return (<MtwaraDistricts />);
}
else if(region==="Njombe"){
    return (<NjombeDistricts />);
}
else if(region==="Rukwa"){
    return (<RukwaDistricts />);
}
else if(region==="Ruvuma"){
    return (<RuvumaDistricts />);
}
else if(region==="Singida"){
    return (<SingidaDistricts />);
}
else if(region==="Shinyanga"){
    return (<ShinyangaDistricts />);
}
else if(region==="Songwe"){
    return (<SongweDistricts />);
}
else if(region==="Geita"){
    return (<GeitaDistricts />);
}
else if(region==="Katavi"){
    return (<KataviDistricts />);
}
else if(region==="Kigoma"){
    return (<KigomaDistricts />);
}
else if(region==="Manyara"){
    return (<ManyaraDistricts />);
}
else if(region==="Simiyu"){
    return (<SimiyuDistricts />);
}
else if(region==="Tabora"){
    return (<TaboraDistricts />);
}
else{
    return null
}




   
}

const ProductsCategories = ()=>{
return(
<Fragment>
  <option style={{fontSize: 20}} value="auto_transportation_bike">Auto/Transportation &amp; Bike</option>
  <option style={{fontSize: 20}} value="fashion_apparel">Fashion &amp; Apparel</option>
  <option style={{fontSize: 20}} value="home_garden">Home &amp; Garden</option>
  <option style={{fontSize: 20}} value="electronics">Electronics</option>
  <option style={{fontSize: 20}} value="beuty_health_medical">Beuty_ Health &amp; Medical</option>
  <option style={{fontSize: 20}} value="food_beverage_fruits">Food_ Beverage &amp; Fruits</option>
  <option style={{fontSize: 20}} value="books_stationary">Books &amp; Stationary</option>
  <option style={{fontSize: 20}} value="electricity_tellecoms">Electricity &amp; Tellecoms</option>
  <option style={{fontSize: 20}} value="security_safety">Security &amp; Safety</option>
  <option style={{fontSize: 20}} value="agriculture">Agriculture</option>
  <option style={{fontSize: 20}} value="sports_entertainment">Sports &amp; Entertainment</option>
  <option style={{fontSize: 20}} value="mineralst">Minerals</option>
  <option style={{fontSize: 20}} value="others">Others Category</option>
</Fragment>     


)





}



const ProductsSubCategories = ({props,category})=>{


const AutoTransportationBike = ()=>{
return(
   <Fragment>

  <option style={{fontSize: 20}} value="automobiles">Automobiles</option>
  <option style={{fontSize: 20}} value="transportations">Transportations</option>
  <option style={{fontSize: 20}} value="garage_accessories">Garage &amp; Accessories</option>
  <option style={{fontSize: 20}} value="motors_bike">Motors &amp; Bike</option>
  <option style={{fontSize: 20}} value="vehicles_tools">Vehicles Tools</option>
  <option style={{fontSize: 20}} value="vehicle_care_cleaning">Vehicle Care &amp; Cleaning</option>
  <option style={{fontSize: 20}} value="vehicles_electrical_system">Vehicles Electrical System</option>
  <option style={{fontSize: 20}} value="vehicles_electronics">Vehicles Electronics</option>
  <option style={{fontSize: 20}} value="others">Others Categories</option>
</Fragment>

)
}

const FashionApparel = ()=>{
    return(
       <Fragment>
  <option style={{fontSize: 20}} value="womens_fashions">Womens Fashions</option>
  <option style={{fontSize: 20}} value="Mens_fashions">Mens Fashions</option>
  <option style={{fontSize: 20}} value="kids_fashions">Kids Fashions</option>
  <option style={{fontSize: 20}} value="fashion_accessories">Fashion Accessories</option>
  <option style={{fontSize: 20}} value="bags_wallets">Bags &amp; Wallets</option>
  <option style={{fontSize: 20}} value="shoes_accessories">Shoes &amp; Accessories</option>
</Fragment>
    
    )
}

const HomeGarden = ()=>{
    return(
<Fragment>
  <option style={{fontSize: 20}} value="kitchen_dining">Kitchen &amp; Dining</option>
  <option style={{fontSize: 20}} value="home_decor">Home Decor</option>
  <option style={{fontSize: 20}} value="lightings">Lightings</option>
  <option style={{fontSize: 20}} value="smart_home_automation">Smart Home Automation</option>
  <option style={{fontSize: 20}} value="garden_supplies">Garden Supplies</option>
  <option style={{fontSize: 20}} value="furniture">Furniture</option>
  <option style={{fontSize: 20}} value="house_cleaning_laundry">House Cleaning &amp; Laundry</option>
  <option style={{fontSize: 20}} value="household_sundries">Household Sundries</option>
  <option style={{fontSize: 20}} value="barware">Barware</option>
  <option style={{fontSize: 20}} value="home_textile">Home Textile</option>
  <option style={{fontSize: 20}} value="others">Others Categories</option>
</Fragment>
    
    )
}
const Electronics = ()=>{
    return(
<Fragment>
  <option style={{fontSize: 20}} value="mobile_phones_tablets">Mobile Phones &amp; Tablets</option>
  <option style={{fontSize: 20}} value="mobile_phones_accessories">Mobile Phones Accessories</option>
  <option style={{fontSize: 20}} value="computers_and_desktop">Computers ,&amp; Desktop</option>  
  <option style={{fontSize: 20}} value="computers_accessories">Computers Accessories</option>
  <option style={{fontSize: 20}} value="wearables_and_smartwathes">Wearables &amp; Smart Watches</option>
  <option style={{fontSize: 20}} value="tv_music_home_theater">Tv,Music and Home Theater</option>
  <option style={{fontSize: 20}} value="cctv_drones_and_security">Cctv,Drones and Security</option>
  <option style={{fontSize: 20}} value="digital_cameras_comecoders">Digital Camerasand Comecoders</option>
  <option style={{fontSize: 20}} value="office_equipment_supplies">Office Equipment and Supplies</option>
  <option style={{fontSize: 20}} value="bluetooth_speakers_and_headphones">Bluetooth,Speakers and Headphones</option>
  <option style={{fontSize: 20}} value="homes_and_kitchen_equipments">Homes&amp;Kitchen Equipmements</option>
  <option style={{fontSize: 20}} value="others">Others Categories</option>

</Fragment>
    
    )
}

const BeautyHealthMedical = ()=>{
    return(
<Fragment>
  <option style={{fontSize: 20}} value="health_nutrition">Health &amp; Nutrition</option>
  <option style={{fontSize: 20}} value="hair_extensions_wigs">Hair Extensions &amp; Wigs</option>
  <option style={{fontSize: 20}} value="fragrances_perfumes">Fragrances &amp; Perfumes</option>
  <option style={{fontSize: 20}} value="medication">Medication</option>
  <option style={{fontSize: 20}} value="herbal_foods_supplements">Herbal&amp;foods supplement</option>
  <option style={{fontSize: 20}} value="hair_saloon_equipmements">Hair Saloon Equipmements</option>
  <option style={{fontSize: 20}} value="feminine_hygiene">Feminine Hygiene</option>
  <option style={{fontSize: 20}} value="nail_supplies">Nail Supplies</option>
  <option style={{fontSize: 20}} value="makeups_beauty">Makeups &amp; Beauty</option>
  <option style={{fontSize: 20}} value="skin_body_care">Skin &amp; Body Care</option>
  <option style={{fontSize: 20}} value="sanitary_paper">Sanitary Paper</option>
  <option style={{fontSize: 20}} value="others">Others Categories</option>
</Fragment>
    
    )
}

const FoodsBeverageFruits = ()=>{
    return(
<Fragment>

  <option style={{fontSize: 20}} value="food">Food</option>
  <option style={{fontSize: 20}} value="beverage">Beverage</option>
  <option style={{fontSize: 20}} value="bakery_goods">Bakery Goods</option>
  <option style={{fontSize: 20}} value="vegetable_products">Vegetable Products</option>
  <option style={{fontSize: 20}} value="seasoning_candiments">Seasoning &amp; Candiments</option>
  <option style={{fontSize: 20}} value="sea_food">Sea Food</option>
  <option style={{fontSize: 20}} value="meat_poultry">Meat &amp; Poultry</option>
  <option style={{fontSize: 20}} value="fruits_products">Fruits Products</option>
  <option style={{fontSize: 20}} value="eggs_eggs_products">Eggs &amp; Eggs Products</option>
  <option style={{fontSize: 20}} value="honey_products">Honey Products</option>
  <option style={{fontSize: 20}} value="others">Others Categories</option>

</Fragment>
    
    )
}

const BooksStationary = ()=>{
    return(
<Fragment>

<option style={{fontSize: 20}} value="books">Books</option>
<option style={{fontSize: 20}} value="stationary_accessories">Stationary Accessories</option>
<option style={{fontSize: 20}} value="arts_crafts">Arts &amp; Crafts</option>
<option style={{fontSize: 20}} value="others">Others Categories</option>


</Fragment>
    
    )
}

const ElectricityTellecoms = ()=>{
    return(
<Fragment>
<option style={{fontSize: 20}} value="electrical_equipmentssupplies">Electrical Equipments Supplies</option>
<option style={{fontSize: 20}} value="telecommunications">Telecommunications</option>
<option style={{fontSize: 20}} value="others">other categories</option>
</Fragment>
    
    )
}


const SecuritySafety = ()=>{
    return(
<Fragment>
<option style={{fontSize: 20}} value="security_protections">Security &amp; Protections</option>
<option style={{fontSize: 20}} value="work_place_safety_supplies">Work Place Safety Supplies</option>
<option style={{fontSize: 20}} value="fire_fighting_supplies">Fire Fighting Supplies</option>
<option style={{fontSize: 20}} value="others">other categories</option>
</Fragment>
    
    )
}

const Agriculture = ()=>{
    return(
<Fragment>
   <option style={{fontSize: 20}} value="raw_materials">Raw Materials</option>
  <option style={{fontSize: 20}} value="animals">Animals</option>
  <option style={{fontSize: 20}} value="poultry">Poultry</option>
  <option style={{fontSize: 20}} value="forestry">Forestry</option>
  <option style={{fontSize: 20}} value="raw_fruits">Raw Fruits</option>
  <option style={{fontSize: 20}} value="others">other categories</option>
</Fragment>
    
    )
}

const SportsEntertainments = ()=>{
    return(
<Fragment>
  
  <option style={{fontSize: 20}} value="video_games_console">Video Games &amp; Console</option>
  <option style={{fontSize: 20}} value="fitness_body_building">Fitness &amp; Body Building</option>
  <option style={{fontSize: 20}} value="indoor_sports">Indoor Sports</option>
  <option style={{fontSize: 20}} value="team_sports">Team Sports</option>
  <option style={{fontSize: 20}} value="amusements_parks">Amusements Parks</option>
  <option style={{fontSize: 20}} value="outdoor_sports">Outdoor Sports</option>
  <option style={{fontSize: 20}} value="water_sports">Water Sports</option>
  <option style={{fontSize: 20}} value="sports_wear">Sports Wear</option>
  <option style={{fontSize: 20}} value="music">Music</option>
  <option style={{fontSize: 20}} value="sports_shoes">Sports Shoes</option>
  <option style={{fontSize: 20}} value="others">other categories</option>
</Fragment>
    
    )
}



useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
        
        
         
        
        
return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------
        
        
        
        
        
}
},[category]) 

 

if(category==="auto_transportation_bike"){

  return AutoTransportationBike()  
}else if(category==="fashion_apparel"){
    

return FashionApparel()
}
else if(category==="home_garden"){
    

return HomeGarden()
}
else if(category==="electronics"){
    

    return Electronics()
    
}
else if(category==="beuty_health_medical"){
    

    return BeautyHealthMedical()
    
    
}
else if(category==="electricity_tellecoms"){
    

    return ElectricityTellecoms()
  
    
}
else if(category==="agriculture"){
    

    return Agriculture()
  
  
    
}
else if(category==="sports_entertainment"){
    

    return SportsEntertainments()
    
  
    
}
else if(category==="security_safety"){
    

    return SecuritySafety()
    
  
    
}
else{
    return (<option style={{fontSize: 20}} value="null">select category first</option>)
}
    
    
    
    
    
}
    
    

  
  
    




















export {
TanzaniaDistrictList,
TanzaniaRegionsList,
DarEsSalaamDistricts,
DodomaDistricts,
MwanzaDistricts,
MbeyaDistricts,
ArushaDistricts,
KigomaDistricts,
PwaniDistricts,
TangaDistricts,
MorogoroDistricts,
PembaDistricts,
IringaDistricts,
LindiDistricts,
MaraDistricts,
UngujaDistricts,
MtwaraDistricts,
NjombeDistricts,
RukwaDistricts,
RuvumaDistricts,
SingidaDistricts,
ShinyangaDistricts,
SongweDistricts,
GeitaDistricts,
KataviDistricts,
ManyaraDistricts,
SimiyuDistricts,
TaboraDistricts,
ProductsCategories,
ProductsSubCategories

};










