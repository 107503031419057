import React ,{Fragment,useEffect,useState,useContext}from 'react';
import Grid from '@material-ui/core/Grid';
//import { makeStyles } from '@material-ui/core/styles';
import {Money,LocalAtm,AccountBalance} from '@material-ui/icons';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import transaction_withdraw from "./transaction_withdraw.svg";
import {DashboardCard,DashboardCardCircle,DashboardCardLogo,DashboardCardText} from "../StyledComponents/styledcomponents";


// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));



    
const LoanApplication = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User} = Appstate
  const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2} = Appsettings

  //const classes = useStyles();

  const {loan_threshold} = User



const [ErrorMessage, setErrorMessage] = React.useState(null);

const [LoanDeductionInfor, setLoanDeductionInfor] = React.useState('');

const [ValidationSuccess, setValidationSuccess] = useState(false);

const [TransactionAccount, setTransactionAccount] = useState({
  initial_withdraw_amount:10000,
  account_balance:25000,
  loan:15000,
  loan_deduction_small_withdraw:25,
  loan_deduction_big_withdraw:50,
  government_tax:3.5,
  transaction_fee:6000,
  pending_transaction_count:3,
  pending_transaction_details:[{}],
  withdraw_report_count:2,
  withdraw_report_details:[{}]

});

const [TransactionForm, setTransactionForm] = useState({
  withdraw_amount:0,
  final_withdraw_amount:0,
  account_balance:0,
  government_tax:0,
  loan_deduction:0,
  transaction_fee:TransactionAccount.transaction_fee

});




useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  







return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])




const OnchangeWithdrawAmount = (event)=>{

  const {value} = event.target
  setTransactionForm({...TransactionForm,withdraw_amount:parseFloat(value)})

}



const TransactionCalculation =()=>{
var LoanPercentPayment = 0
var WithdrawAmount = TransactionForm.withdraw_amount


if(TransactionAccount.loan===0){//customer has no loan ,no deduction will be applied(only goverment tax and transaction feee)

  

  var MinusTransactionFee =  (WithdrawAmount - TransactionAccount.transaction_fee) //minus transaction fee
  var MinusGvTax = MinusTransactionFee - (TransactionAccount.government_tax/100)*WithdrawAmount//minus gv tax
  var FinalWithdrawAmount = MinusGvTax


   //fill data for server submission
   setTransactionForm({...TransactionForm,
    government_tax:Math.floor((TransactionAccount.government_tax/100)*WithdrawAmount),
    withdraw_amount:WithdrawAmount,
    final_withdraw_amount:FinalWithdrawAmount,
    account_balance:TransactionAccount.account_balance - WithdrawAmount
    
    })
    
    setTransactionAccount({...TransactionAccount,account_balance:TransactionAccount.account_balance - WithdrawAmount})


 
 }else if(TransactionAccount.loan > 0){//customer has a loan(loan deduction,government tax,transaction fee)

  if(TransactionForm.withdraw_amount >= TransactionAccount.loan){//50% deduction of withdrawn ammount to pay for loan

    LoanPercentPayment  = (TransactionAccount.loan_deduction_big_withdraw/100)*TransactionAccount.loan

    setLoanDeductionInfor(`${TransactionAccount.loan_deduction_big_withdraw}% of your loan debt has been paid`)

  }else if(TransactionForm.withdraw_amount < TransactionAccount.loan){//25% of loan deducted from withdrawn amount to pay loan debt

    LoanPercentPayment  = (TransactionAccount.loan_deduction_small_withdraw/100)*WithdrawAmount

    setLoanDeductionInfor(`${TransactionAccount.loan_deduction_small_withdraw}% of your withdrawn amount`)



  }


  MinusTransactionFee =  (WithdrawAmount - TransactionAccount.transaction_fee) //minus transaction fee
  MinusGvTax = MinusTransactionFee - (TransactionAccount.government_tax/100)*WithdrawAmount//minus gv tax
  //var MinusLoan = MinusGvTax - LoanPercentPayment//minus loan debt





   //fill data for server submission
   setTransactionForm({...TransactionForm,
    government_tax:Math.floor((TransactionAccount.government_tax/100)*WithdrawAmount),
    withdraw_amount:WithdrawAmount,
    final_withdraw_amount:FinalWithdrawAmount,
    account_balance:TransactionAccount.account_balance - WithdrawAmount,
    loan_deduction:LoanPercentPayment
    
    })
    
    setTransactionAccount({...TransactionAccount,account_balance:TransactionAccount.account_balance - WithdrawAmount})





}

    

}




const WithdrawValidation =()=>{
  return new Promise(function(resolve,reject){

    if(parseFloat(TransactionForm.withdraw_amount) > parseFloat(TransactionAccount.account_balance)){//CHECK FOR SUFFICIENT FUND

      setValidationSuccess(false)//turn off valid table
     return reject({message:`insufficient balance to withdraw  ${TransactionForm.withdraw_amount}. your balance is ${TransactionAccount.account_balance}`})

    }if(parseFloat(TransactionForm.withdraw_amount) < parseFloat(TransactionAccount.initial_withdraw_amount)){//CHECK FOR A VALID INITIAL WITHDRAW AMOUNT

      setValidationSuccess(false)
      return reject({message:`please the minimum amount to withdraw is TSH ${TransactionAccount.initial_withdraw_amount}`})
     }
    
    else{ //APPLY TRANSACTION CALCULATION
       
      setValidationSuccess(true)
      setErrorMessage(null)
      return resolve({message:`your request is being prossesed you will receive a message`})
    }

 
})
}

const WithdrawConfirmation =()=>{

  WithdrawValidation().then(function(JsonResponse){
   
    TransactionCalculation()

    

  }).catch(function(Response){
    const {message} = Response
    
    setErrorMessage(message)
  

  })

}
    

const ConfirmWithdrawForm = ()=>{


  console.log(TransactionForm)
}








// const WithdrawReportMap = [0,1,2,3,4,5].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// })  



const PendingTransactionMap= [0].map(function(object,index){
  return(
     <React.Fragment key={index}>
    <div className="card collapsed-card">
  <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
    <h3 className="card-title">TSH 230000</h3>
    <div className="card-tools">
      <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
      </button>
    </div>
    {/* /.card-tools */}
  </div>
  {/* /.card-header */}
  <div className="card-body" style={{display: 'none'}}>
    The body of the card
  </div>
  {/* /.card-body */}
</div>

  <br />
     </React.Fragment>
     )
}) 



return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="transaction-wrapper">


    
<Grid container spacing={1}>

<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><AccountBalance style={{color:themecolor1,fontSize:60}}/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>LOAN THRESHOLD</b></li>
        <li style={{color:'#fff',display:'block'}}><span>TSH {CurrencyFormat(loan_threshold)}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' data-bs-toggle="modal" data-bs-target="#loan-application-modal">apply loan</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle> <LocalAtm style={{color:themecolor1,fontSize:60}}/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>LOAN DEBT</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{CurrencyFormat(0)}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' data-bs-toggle="modal" data-bs-target="#loan-payment-modal">pay loan</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><Money style={{color:themecolor1,fontSize:60}}/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>PENDING LOAN REQUEST</b></li>
        <li style={{color:'#fff',display:'block'}}><span>0</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' data-bs-toggle="modal" data-bs-target="#pending-transaction-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>








</Grid>


{/* ----------WITHDAW MODAL------------- */}
<div>
  <div className="modal fade" id="loan-application-modal" data-bs-backdrop='static' data-bs-keyboard="false" tabIndex={-1} aria-labelledby="loan-application-label" aria-hidden="true">
    <div className="modal-dialog" style={{border:`4px solid ${themecolor1}`}}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="loan-application-label">LOAN APPLICATION</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className='account-withdraw-banner' style={{backgroundColor:`${themecolor1}`}}><div className='account-withdraw-icon'><img src={transaction_withdraw} alt='' /></div> </div>
            
                 <div className="input-group">
  <div className="input-group-prepend">
    <span className="input-group-text" style={{color:`${themecolor1}`}}>
      AMOUNT
    </span>
  </div>
  <input type='number' className="form-control" id='account-withdraw-input' onChange={OnchangeWithdrawAmount}/>
  <div className="input-group-append">
    <div className="input-group-text">
               <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button color='primary'>Apply</Button>
               </ButtonGroup>
    
    </div>
  </div>
</div>    
<br />      

                 {/* ------ERROR MESSAGE----------- */}
               
                <div class="alert alert-info alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  <h5><i class="icon fas fa-info"></i> Alert!</h5>
                     your not qualify to apply for loan yet,you need 150+ LCP to be qualified
                </div>
                


{/* -----------TRANSACTION TABLE------------------- */}
          {ValidationSuccess===true?
           <div className="row">
  <div className="col-12">
    <div className="card">
      <div className="card-body table-responsive p-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>G/Tax <br/>{TransactionAccount.government_tax}%</th>
              <th>Trans/Fee <br />TSH {TransactionAccount.transaction_fee}</th>
              <th>Loan deduction</th>
              <th>Withdraw amount</th>
              <th>Balance</th>
              <th>Bank details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><div className='transaction-table-data'>Sundar putrih de jackson</div></td>
              <td><div className='transaction-table-data'>{TransactionForm.government_tax} </div></td>
              <td><div className='transaction-table-data'>{TransactionAccount.transaction_fee} </div></td>
              <td><div className='transaction-table-data'>{`${TransactionForm.loan_deduction}`}<br/>{LoanDeductionInfor}</div></td>
              <td><div className='transaction-table-data'>TSH {TransactionForm.final_withdraw_amount} </div></td>
              <td><div className='transaction-table-data'>TSH {TransactionAccount.account_balance} </div></td>
              <td><div className='transaction-table-data'><b>BANK: </b>NMB BANK<br /><b>ACCOUNT</b>: 3345644567 <br /><b>NAME :</b>Sundar putrih</div></td>
            </tr>
     
          </tbody>
        </table>
      </div>
      {/* /.card-body */}
    </div>
    {/* /.card */}
  </div>
</div>
          :null}
          {ValidationSuccess===true?
        <ButtonGroup size='large' fullWidth={true} aria-label="small outlined button group">
          <Button color='primary' onClick={ConfirmWithdrawForm}>Confirm transaction</Button>
        </ButtonGroup>
        :null}
        </div>
       
       
       
       
        <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>
 


 {/* --------PENDING TRANSACTION MODAL ------------*/}
<div>
  <div className="modal fade" id="pending-transaction-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="pending-transaction-modal-label" aria-hidden="true">
    <div className="modal-dialog modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="pending-transaction-modal-label">PENDING LOAN REQUEST</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
            
            {/* {PendingTransactionMap} */}
          
        </div>
        <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>


{/* ---------LOAN PAYMENT MODAL---------  */}
<div>
<div className="modal fade" id="loan-payment-modal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="loan-payment-modalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="loan-payment-modalLabel">LOAN PAYMENT PORTAL</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">

          <div className='account-withdraw-banner' style={{backgroundColor:`${themecolor1}`}}><div className='account-withdraw-icon'><img src={transaction_withdraw} alt=''/></div> </div>
            <div className="input-group">
  <div className="input-group-prepend">
    <span className="input-group-text" style={{color:`${themecolor1}`}}>
      AMOUNT
    </span>
  </div>
  <input type='number' className="form-control" id='account-withdraw-input' onChange={OnchangeWithdrawAmount}/>
  <div className="input-group-append">
    <div className="input-group-text">
               <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button color='primary'>Apply</Button>
               </ButtonGroup>
    
    </div>
  </div>
</div>    
<br />      

                 {/* ------ERROR MESSAGE----------- */}
         
                <div class="alert alert-info alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  <h5><i class="icon fas fa-info"></i> Alert!</h5>
                     you dont have loan debt yet
                </div>
         


{/* -----------TRANSACTION TABLE------------------- */}
          {ValidationSuccess===true?
           <div className="row">
  <div className="col-12">
    <div className="card">

    
      {/* /.card-header */}
      <div className="card-body table-responsive p-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>G/Tax <br/>{TransactionAccount.government_tax}%</th>
              <th>Trans/Fee <br />TSH {TransactionAccount.transaction_fee}</th>
              <th>Loan deduction</th>
              <th>Withdraw amount</th>
              <th>Balance</th>
              <th>Bank details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><div className='transaction-table-data'>Sundar putrih de jackson</div></td>
              <td><div className='transaction-table-data'>{TransactionForm.government_tax} </div></td>
              <td><div className='transaction-table-data'>{TransactionAccount.transaction_fee} </div></td>
              <td><div className='transaction-table-data'>{`${TransactionForm.loan_deduction}`}<br/>{LoanDeductionInfor}</div></td>
              <td><div className='transaction-table-data'>TSH {TransactionForm.final_withdraw_amount} </div></td>
              <td><div className='transaction-table-data'>TSH {TransactionAccount.account_balance} </div></td>
              <td><div className='transaction-table-data'><b>BANK: </b>NMB BANK<br /><b>ACCOUNT</b>: 3345644567 <br /><b>NAME :</b>Sundar putrih</div></td>
            </tr>
     
          </tbody>
        </table>
      </div>
      {/* /.card-body */}
    </div>
    {/* /.card */}
  </div>
</div>
          :null}
          {ValidationSuccess===true?
        <ButtonGroup size='large' fullWidth={true} aria-label="small outlined button group">
          <Button color='primary' onClick={ConfirmWithdrawForm}>Confirm transaction</Button>
        </ButtonGroup>
        :null}
        </div>
       
       
        <div className="modal-footer">
               <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>

  


</div>
</Fragment>
 
    
);


// LoanApplication.propTypes = {}
        
// LoanApplication.defaultProps ={}


}
export {LoanApplication};

