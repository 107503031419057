import React ,{Fragment,useEffect,useState,useContext}from 'react';
import './Transaction.scss';
import Grid from '@material-ui/core/Grid';
//import { makeStyles } from '@material-ui/core/styles';
import {AccountBalance,History,LocalAtm} from '@material-ui/icons';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import transaction_withdraw from "./transaction_withdraw.svg";
import {DashboardCard,DashboardCardCircle,DashboardCardLogo,DashboardCardText,DashboardTextHeader} from "../StyledComponents/styledcomponents";
import {ServerFirstIndexedb_TransactionWithdrawRequest,ServerFirstIndexedb_FetchTransactionalAccount} from "../../HttpcallUserApp";
import {ScaleLoader} from "react-spinners";
import {NotificationActivate} from "../NotificationApplication/NotificationApplication";
import $ from 'jquery';


// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));



    
const Transaction = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User,App} = Appstate
  const {CurrencyFormat,DeductAccountBalance} = Functions
   const {themecolor1,themecolor2} = Appsettings

   const {balance} = User

  //const classes = useStyles();

  const {server_ip,authentication_token} = App


const [ErrorMessage, setErrorMessage] = React.useState(null);

const [LoanDeductionInfor, setLoanDeductionInfor] = React.useState('');

const [ValidationSuccess, setValidationSuccess] = useState(false);

const [ServerResponse, setServerResponse] = useState(null);

const [WithdrawLoading, setWithdrawLoading] = useState(false);





const [TransactionCondition, setTransactionCondition] = useState({
  initial_withdraw_amount:10000,
  loan_deduction_small_withdraw:25,
  loan_deduction_big_withdraw:50,
  government_tax:3.5,
  transaction_fee:3500
 });



const [TransactionAccount, setTransactionAccount] = useState({
  loan:0,
  pending_transaction_count:0,
  withdrawn_transaction_count: 0,
  rejected_transaction_count:0,
  rejected_transaction_report:[],
  pending_transaction_details:[
    // {
    //   approved: true,
    //   bank_account_fullname: "william jonasan",
    //   bank_name: "Axis Bank",
    //   bank_number: "03464657573857648",
    //   final_withdraw_amount: 23000,
    //   gv_tax: 200,
    //   loan_deduction: 0,
    //   pending: false,
    //   rejected: false,
    //   transaction_fee: 6000,
    //   withdraw_amount: 25000,
   //   transaction_status
      //
    // }
  ],
  withdrawn_transaction_report:[
    // {
    //   approved: true,
    //   bank_account_fullname: "william jonasan",
    //   bank_name: "Axis Bank",
    //   bank_number: "03464657573857648",
    //   final_withdraw_amount: 23000,
    //   gv_tax: 200,
    //   loan_deduction: 0,
    //   pending: false,
    //   rejected: false,
    //   transaction_fee: 6000,
    //   withdraw_amount: 25000
     //  transaction_status
    // }
  ],
  bank_name:'',
  bank_account_fullname:'',
  bank_number:''
 });



const [TransactionRequestForm, setTransactionRequestForm] = useState({
  withdraw_amount:0,
  final_withdraw_amount:0,
  balance:0,
  government_tax:0,
  loan_deduction:0,
  transaction_fee:TransactionAccount.transaction_fee

});




useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  
 ServerFirstIndexedb_FetchTransactionalAccount(server_ip,authentication_token).then(function(JsonResponse){
     
     const {status} = JsonResponse
     if(status===200){
       const  {Jsondata} = JsonResponse

       setTransactionAccount(Jsondata)
      



     }
  
    console.log(JsonResponse)

  })





return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[User])




const OnchangeWithdrawAmount = (event)=>{

  const {value} = event.target

  setTransactionRequestForm({...TransactionRequestForm,withdraw_amount:parseFloat(value)})

  setValidationSuccess(false)

}



const TransactionCalculation =()=>{

var AccountBalance = balance  
var LoanPercentPayment = 0
var WithdrawAmount = TransactionRequestForm.withdraw_amount

var MinusTransactionFee = 0
var MinusGvTax = 0
var FinalWithdrawAmount = 0
var MinusLoan = 0


if(TransactionAccount.loan ===0){//customer has no loan ,no deduction will be applied(only goverment tax and transaction feee)

  MinusTransactionFee =  (WithdrawAmount - TransactionCondition.transaction_fee)//minus transaction fee
  MinusGvTax = MinusTransactionFee - (TransactionCondition.government_tax/100)*WithdrawAmount//minus gv tax
  FinalWithdrawAmount = MinusGvTax


   //fill data for server submission
    setTransactionRequestForm({...TransactionRequestForm,
    government_tax:Math.floor((TransactionCondition.government_tax/100)*WithdrawAmount),
    withdraw_amount:WithdrawAmount,
    final_withdraw_amount:FinalWithdrawAmount,
    balance:balance - WithdrawAmount,
    transaction_fee:TransactionCondition.transaction_fee
    
    })
    
    //setTransactionAccount({...TransactionAccount,account_balance:TransactionAccount.account_balance - WithdrawAmount})


 
 }else if(TransactionAccount.loan > 0){//customer has a loan(loan deduction,government tax,transaction fee)

  if(TransactionRequestForm.withdraw_amount >= TransactionAccount.loan){//50% deduction of withdrawn ammount to pay for loan

    LoanPercentPayment  = (TransactionCondition.loan_deduction_big_withdraw/100)*TransactionAccount.loan

    setLoanDeductionInfor(`${TransactionCondition.loan_deduction_big_withdraw}% of your loan debt has been paid`)

  }else if(TransactionRequestForm.withdraw_amount < TransactionAccount.loan){//25% of loan deducted from withdrawn amount to pay loan debt

    LoanPercentPayment  = (TransactionCondition.loan_deduction_small_withdraw/100)*WithdrawAmount

    setLoanDeductionInfor(`${TransactionCondition.loan_deduction_small_withdraw}% of your withdrawn amount`)



  }

  MinusTransactionFee =  (WithdrawAmount - TransactionCondition.transaction_fee)//minus transaction fee
  MinusGvTax = MinusTransactionFee - (TransactionCondition.government_tax/100)*WithdrawAmount//minus gv tax
  MinusLoan = MinusGvTax - LoanPercentPayment//minus loan debt

  FinalWithdrawAmount = MinusGvTax



    //fill data for server submission
    setTransactionRequestForm({...TransactionRequestForm,
      government_tax:Math.floor((TransactionCondition.government_tax/100)*WithdrawAmount),
      withdraw_amount:WithdrawAmount,
      final_withdraw_amount:FinalWithdrawAmount,
      balance:balance - WithdrawAmount,
      transaction_fee:TransactionCondition.transaction_fee,
      loan_deduction:LoanPercentPayment
      
      })
    
  //   //setTransactionAccount({...TransactionAccount,account_balance:TransactionAccount.account_balance - WithdrawAmount})





}

    

}




const WithdrawValidation =()=>{
  return new Promise(function(resolve,reject){

    if(parseFloat(TransactionRequestForm.withdraw_amount) > balance){//CHECK FOR SUFFICIENT FUND

      setValidationSuccess(false)//turn off valid table
     return reject({message:`insufficient balance to withdraw  ${TransactionRequestForm.withdraw_amount}. your balance is ${TransactionAccount.account_balance}`})

    }if(parseFloat(TransactionRequestForm.withdraw_amount) < parseFloat(TransactionCondition.initial_withdraw_amount)){//CHECK FOR A VALID INITIAL WITHDRAW AMOUNT

      setValidationSuccess(false)
      return reject({message:`please the minimum amount to withdraw is TSH ${TransactionCondition.initial_withdraw_amount}`})
     }
    
    else{ //APPLY TRANSACTION CALCULATION
       
      setValidationSuccess(true)
      setErrorMessage(null)
      return resolve({message:`your request is being prossesed you will receive a message`})
    }

 
})
}

const WithdrawConfirmation =()=>{

  WithdrawValidation().then(function(JsonResponse){
   
    TransactionCalculation()

    

  }).catch(function(Response){
    const {message} = Response
    
    setErrorMessage(message)
  

  })

}
    

const ConfirmWithdrawForm = ()=>{
  setWithdrawLoading(true)

    
   console.log(TransactionRequestForm)


   ServerFirstIndexedb_TransactionWithdrawRequest(server_ip,TransactionRequestForm,authentication_token).then(function(JsonResponse){
    const {status} = JsonResponse
    setWithdrawLoading(false)
    setValidationSuccess(false) //

    if(status===200){


      console.log(JsonResponse)
      DeductAccountBalance(TransactionRequestForm.withdraw_amount)


      NotificationActivate('your request is submitted successfully , you will receive a confirmation soon','success',true,true)
      
        var LoginRefreshPage =  $('#close-transactional-modal')
        LoginRefreshPage[0].click()



    }
}).catch(function(JsonResponse){


  setWithdrawLoading(false)
})



}


const ReportStatusDisplayer=({transaction_status})=>{
return(
  <React.Fragment>
    {transaction_status==='pending'?
     <span class="badge bg-primary">pending</span>
    :null
    }
    {transaction_status==='approved'?
     <span class="badge bg-success">approved</span>
    :null}
     {transaction_status==='rejected'?
     <span class="badge bg-danger">rejected</span>
    :null}
</React.Fragment>
)
}







const PendingTransactionMap= TransactionAccount.pending_transaction_details.map(function(object,index){
  return(
  <React.Fragment key={index}>
    <div className="card collapsed-card">
  <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
    <h3 className="card-title" style={{color:'#fff'}}>TSH {CurrencyFormat(object.withdraw_amount)}</h3>
    <div className="card-tools">
      <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
      </button>
    </div>
  </div>
  <div className="card-body" style={{display: 'none'}}>
    <ul className='pending-transaction-list'>
      <li><b>Withdraw Amount</b>: {CurrencyFormat(object.withdraw_amount)}</li>
      <div className='transaction-report-divider'></div>
      <li><b>GTax</b> : {CurrencyFormat(object.gv_tax)} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Transaction Fee</b>: {CurrencyFormat(object.transaction_fee)} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Loan Deduction</b> :  {CurrencyFormat(object.loan_deduction)}  </li>
      <div className='transaction-report-divider'></div>
      <li><b>Final Amount</b>: {CurrencyFormat(object.final_withdraw_amount)}  </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Name</b> :{object.bank_name} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Account full Name</b> :{object.bank_account_fullname} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Number</b> : {object.bank_number}  </li>
       <br />
      <li><b>STATUS</b> : <ReportStatusDisplayer transaction_status={object.transaction_status} /></li>
    
    </ul>
  </div>
</div>
<br />
 </React.Fragment>
)
}) 





const WithdrawReportMap = TransactionAccount.withdrawn_transaction_report.map(function(object,index){
  return(
  <React.Fragment key={index}>
    <div className="card collapsed-card">
  <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
    <h3 className="card-title" style={{color:'#fff'}}>TSH {CurrencyFormat(object.withdraw_amount)}</h3>
    <div className="card-tools">
      <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
      </button>
    </div>
  </div>
  <div className="card-body" style={{display: 'none'}}>
    <ul className='pending-transaction-list'>
      <li><b>Withdraw Amount</b>: {CurrencyFormat(object.withdraw_amount)}</li>
      <div className='transaction-report-divider'></div>
      <li><b>GTax</b> : {CurrencyFormat(object.gv_tax)} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Transaction Fee</b>: {CurrencyFormat(object.transaction_fee)} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Loan Deduction</b> :  {CurrencyFormat(object.loan_deduction)}  </li>
      <div className='transaction-report-divider'></div>
      <li><b>Final Amount</b>: {CurrencyFormat(object.final_withdraw_amount)}  </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Name</b> :{object.bank_name} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Account full Name</b> :{object.bank_account_fullname} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Number</b> : {object.bank_number}  </li>
      <br />
      <li><b>STATUS</b> : <ReportStatusDisplayer transaction_status={object.transaction_status} /></li>
    

      
    </ul>
  </div>
</div>
<br />
 </React.Fragment>
)
}) 




const RejectedTransactionMap = TransactionAccount.rejected_transaction_report.map(function(object,index){
  return(
  <React.Fragment key={index}>
    <div className="card collapsed-card">
  <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
    <h3 className="card-title" style={{color:'#fff'}}>TSH {CurrencyFormat(object.withdraw_amount)}</h3>
    <div className="card-tools">
      <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
      </button>
    </div>
  </div>
  <div className="card-body" style={{display: 'none'}}>
    <ul className='pending-transaction-list'>
      <li><b>Withdraw Amount</b>: {CurrencyFormat(object.withdraw_amount)}</li>
      <div className='transaction-report-divider'></div>
      <li><b>GTax</b> : {CurrencyFormat(object.gv_tax)} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Transaction Fee</b>: {CurrencyFormat(object.transaction_fee)} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Loan Deduction</b> :  {CurrencyFormat(object.loan_deduction)}  </li>
      <div className='transaction-report-divider'></div>
      <li><b>Final Amount</b>: {CurrencyFormat(object.final_withdraw_amount)}  </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Name</b> :{object.bank_name} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Account full Name</b> :{object.bank_account_fullname} </li>
      <div className='transaction-report-divider'></div>
      <li><b>Bank Number</b> : {object.bank_number}  </li>
      <br />
      <li><b>STATUS</b> : <ReportStatusDisplayer transaction_status={object.transaction_status} /></li>
    

      
    </ul>
  </div>
</div>
<br />
 </React.Fragment>
)
}) 





return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="transaction-wrapper">


    
<Grid container spacing={1}>

<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><AccountBalance style={{color:themecolor1,fontSize:60}}/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b> ACCOUNT BALANCE</b></li>
        <li style={{color:'#fff',display:'block'}}><span>TSH {CurrencyFormat(balance)}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' data-bs-toggle="modal" data-bs-target="#account-withdraw-modal">withdraw</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle> <LocalAtm style={{color:themecolor1,fontSize:60}}/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>  WITHDRAW REPORT</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{TransactionAccount.withdrawn_transaction_count}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary'  data-bs-toggle="modal" data-bs-target="#withdraw-report-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><History style={{color:themecolor1,fontSize:60}}/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b> PENDING TRANSACTION </b></li>
        <li style={{color:'#fff',display:'block'}}><span>{TransactionAccount.pending_transaction_count}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' data-bs-toggle="modal" data-bs-target="#pending-transaction-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><History style={{color:themecolor1,fontSize:60}}/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>  REJECTED TRANSACTION</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{TransactionAccount.rejected_transaction_count}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' data-bs-toggle="modal" data-bs-target="#rejected-transaction-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>








</Grid>


{/* ----------WITHDAW MODAL------------- */}
<div>
  <div className="modal fade" id="account-withdraw-modal" data-bs-backdrop='static' data-bs-keyboard="false" tabIndex={-1} aria-labelledby="account-withdraw-label" aria-hidden="true">
    <div className="modal-dialog" style={{border:`4px solid ${themecolor1}`}}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="account-withdraw-label">ACCOUNT WITHDRAW</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className='account-withdraw-banner' style={{backgroundColor:`${themecolor1}`}}><div className='account-withdraw-icon'><img src={transaction_withdraw} alt='account withdraw'/></div> </div>
            
                 <div className="input-group">
  <div className="input-group-prepend">
    <span className="input-group-text" style={{color:`${themecolor1}`}}>
      AMOUNT
    </span>
  </div>
  <input type='number' className="form-control" id='account-withdraw-input' onChange={OnchangeWithdrawAmount}/>
  <div className="input-group-append">
    <div className="input-group-text">
               <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button color='primary' onClick={WithdrawConfirmation}>Apply</Button>
               </ButtonGroup>
    
    </div>
  </div>
</div>    
<br />      

                 {/* ------ERROR MESSAGE----------- */}
                 {ErrorMessage !==null?
                <div class="alert alert-info alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  <h5><i class="icon fas fa-info"></i> Alert!</h5>
                     {ErrorMessage}
                </div>
                :null}


{/* -----------TRANSACTION TABLE------------------- */}
          {ValidationSuccess===true?
           <div className="row">
  <div className="col-12">
    <div className="card">

    
      {/* /.card-header */}
      <div className="card-body table-responsive p-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>G/Tax <br/>{TransactionCondition.government_tax}%</th>
              <th>Trans/Fee <br />TSH {TransactionAccount.transaction_fee}</th>
              <th>Loan deduction</th>
              <th>Withdraw amount</th>
              <th>Balance</th>
              <th>Bank details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><div className='transaction-table-data'>{TransactionAccount.bank_account_fullname} </div></td>
              <td><div className='transaction-table-data'>{TransactionRequestForm.government_tax} </div></td>
              <td><div className='transaction-table-data'>{TransactionRequestForm.transaction_fee} </div></td>
              <td><div className='transaction-table-data'>{`${TransactionRequestForm.loan_deduction}`}<br/>{LoanDeductionInfor}</div></td>
              <td><div className='transaction-table-data'>TSH {TransactionRequestForm.final_withdraw_amount} </div></td>
              <td><div className='transaction-table-data'>TSH {TransactionRequestForm.balance} </div></td>
              <td><div className='transaction-table-data'><b>BANK: </b>{TransactionAccount.bank_name}<br /><b>ACCOUNT</b>: {TransactionAccount.bank_number} <br /><b>NAME :</b>{TransactionAccount.bank_account_fullname}  </div></td>
            </tr>
     
          </tbody>
        </table>
      </div>
      {/* /.card-body */}
    </div>
    {/* /.card */}
   </div>
  </div>
  :null}

              <div className='transaction-withdraw-loading-wrapper'>
                <ScaleLoader speedMultiplier={2} loading={WithdrawLoading} height={50} width={10} color={themecolor1} />
                </div>
                
      {ValidationSuccess===true?
        <ButtonGroup size='large' fullWidth={true} aria-label="small outlined button group">
          <Button color='primary' onClick={ConfirmWithdrawForm}>Confirm transaction</Button>
        </ButtonGroup>
        :null}
        </div>
        <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary' id='close-transactional-modal'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>
 


 {/* --------PENDING TRANSACTION MODAL ------------*/}
<div>
  <div className="modal fade" id="pending-transaction-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="pending-transaction-modal-label" aria-hidden="true">
    <div className="modal-dialog modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="pending-transaction-modal-label">PENDING TRANSACTIONS</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
            
            {PendingTransactionMap}
          
        </div>
        <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>


{/* ---------WITHDRAW REPORT---------  */}
<div>
  <div className="modal fade" id="withdraw-report-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="withdraw-report-modal-label" aria-hidden="true">
    <div className="modal-dialog modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="withdraw-report-modal-label">WITHDRAW REPORTS</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          
           {WithdrawReportMap}
         
      
        </div>
        <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>



{/* ---------REJECTED TRANSACTION REPORT---------  */}
<div>
  <div className="modal fade" id="rejected-transaction-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="rejected-transaction-modal-label" aria-hidden="true">
    <div className="modal-dialog modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="rejected-transaction-modal-label">REJECTED TRANSACTIONS</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          
           {RejectedTransactionMap}
         
      
        </div>
        <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>



  


</div>
</Fragment>
 
    
);


// Transaction.propTypes = {}
        
// Transaction.defaultProps ={}


}
export {Transaction};

