import React ,{Fragment,useEffect,useContext}from 'react';
import './Dashboard.scss';
import Grid from '@material-ui/core/Grid';
//import { makeStyles } from '@material-ui/core/styles';
//import {Money,History,LocalAtm,AccountBalance} from '@material-ui/icons';
import {UseraccountContextApi} from "../../UseraccountContextApi";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
//import transaction_withdraw from "./transaction_withdraw.svg";
import balance_icon from './balance_icon.svg';
import ppc_points_icon from './ppc_points_icon.svg';
import lcp_points_icon from "./lcp_points_icon.svg";
import rcp_points_icon from "./rcp_points_icon.svg";
import network_icon from "./network_icon.svg";
//import {ArrowRightAlt} from '@material-ui/icons';
import {DashboardCard,DashboardCardCircle,DashboardCardLogo,DashboardCardText} from "../StyledComponents/styledcomponents";

//import {MoreVert} from '@material-ui/icons';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));



    
const Dashboard = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,User} = Appstate
  const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2} = Appsettings

  //const classes = useStyles();


const [DashboardInfor, setDashboardInfor] = React.useState({
  title:'',
  description:'',
  icon:'',
});




const {balance,lcp,community,ppc,lcp_quntity,ppc_quntity,rcp_quntity} = User


// balance: 0,
// lcp: 0,
// community: 0,
// ppc: 0,
// distributor_points: 0,
// first_name: "",
// generation5_points: 0,
// generation6_points: 0,
// generation7_points: 0,
// gold_points: 0,
// last_name: "",
// middle_name: "",
// personal_message: [],
// profile_photo: "",
// rank: "",
// rubby_points: 0,
// silver_points: 0,
// system_notification: [],
// tanzanite_points: 0,
// transactional_messages: [],
// upliner_key_collection: []
// },



useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  



//0683300300 msangwa herbal kona ya nyamhongoro



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])




const ActivateModalInfor = (dashboard)=>{

  if(dashboard==='ppc'){
  setDashboardInfor({
      title:'MY CPV POINTS',
      description:`
        Purchases Point Value(PPC) ,points earned per each products purchases,
        from your personal purchases as well as your recruited network.
        this value yield to monetary value and generate (ACB) Tsh which 
        will be deposited direct to your account at each 28 date of the month.
        we encouradge you and your peer network to conduct more product purchases to increase this value
        `,
        icon:lcp_points_icon
    })
 
  
  }else if(dashboard==='balance'){
    setDashboardInfor({
      title:'MY BALANCE',
      icon:balance_icon,
      description:<Fragment>
        <p>Your account balance has a monetary value  which rates in Tsh only 
          , you can withdraw and get direct cash through your mobile account.
         you can recharges your account balance by several means .
        </p>
       
       <ul>
         <li>customer referral</li>
         <li>product purchases </li>
         <li>Loan application </li>
       </ul>
        
      </Fragment>
      
    })
  
  }
  else if(dashboard==='lcp'){
    setDashboardInfor({
      title:'MY LCP POINTS',
      description:<Fragment>
        <p>LOAN CREDIT POINT (LCP) is the key point that enhances your ability to apply for a loan. The higher your LCP points, the greater the loan amount you can access. </p>
        <ul>
          <li><b>you can generate more LCP point by</b></li>
         <li>customer referral</li>
         <li>product purchases </li>
       </ul>

      </Fragment>,
      icon:ppc_points_icon
    })
  
  }else if(dashboard==='network'){
    setDashboardInfor({
      title:'MY NETWORK',
      description:<p>Each individual who has joined Femsworldtz through your links or your membership ID are pressumed as your network<br/>You get a commission whenever you invite a new member to join.</p>,
      icon:network_icon
    })
  
  }else if(dashboard==='rcp'){
    setDashboardInfor({
      title:'MY RCP POINTS',
      description:<Fragment>
        <p>REWARD CREDIT POINT (RCP) , these points increases the chance of winning a high prize rewards , the more the point the high the change of winning a prize </p>
        <ul>
          <li><b>RCP can be generated by</b></li>
         <li>product purchases , your personal purchases and your peers can generate more RCP point leading you to earn more rewards </li>
       </ul>
      </Fragment>,
      icon:network_icon
    })
  
  }
  
  
  
  }
  
  





// const WithdrawReportMap = [0,1,2,3,4,5].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// })  



// const PendingTransactionMap= [0].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// }) 



return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="dashboard-wrapper">


    
<Grid container spacing={1}>

<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={balance_icon} alt='account balance' className='dashboard-image'/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>ACCOUNT CREDIT BALANCE &nbsp; (ACB)</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{CurrencyFormat(balance)}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('balance')}} data-bs-toggle="modal" data-bs-target="#ppc-points-modal">Moreinfor</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={ppc_points_icon} alt='ppc account' className='dashboard-image'/></DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>PURCHASES POINT CREDIT &nbsp; (PPC)</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{ppc_quntity}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('ppc')}} data-bs-toggle="modal" data-bs-target="#ppc-points-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>


<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={lcp_points_icon} alt='lcp account' className='dashboard-image'/> </DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>LOAN CREDIT POINT &nbsp;  (LCP)</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{lcp_quntity}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('lcp')}} data-bs-toggle="modal" data-bs-target="#ppc-points-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>

<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={rcp_points_icon} alt='lcp account' className='dashboard-image'/> </DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>REWARD CREDIT POINT&nbsp; (RCP)</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{rcp_quntity}</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('rcp')}} data-bs-toggle="modal" data-bs-target="#ppc-points-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>




<Grid item xs={12} sm={4}>
  <DashboardCard>  
    <DashboardCardLogo bgcolor={themecolor1}>
      <DashboardCardCircle><img src={network_icon} alt='my network' className='dashboard-image'/> </DashboardCardCircle>
    </DashboardCardLogo>
    <DashboardCardText bgcolor={themecolor2} boldercolor={themecolor1}>
    <ul style={{color:'#fff',display:'block'}}>
        <li style={{display:'block'}}><b>MY COMMUNITY</b></li>
        <li style={{color:'#fff',display:'block'}}><span>{community} community</span></li>   
              <ButtonGroup size="small" aria-label="small outlined button group" style={{position:'relative',top:'10%'}}>
                 <Button color='primary' onClick={()=>{ActivateModalInfor('network')}} data-bs-toggle="modal" data-bs-target="#ppc-points-modal">Moreinfo</Button>
               </ButtonGroup>
       </ul> 
    </DashboardCardText>
   </DashboardCard>
</Grid>








</Grid>


{/* ----------MODAL------------- */}
<div>
  <div className="modal fade" id="ppc-points-modal" data-bs-backdrop='static' data-bs-keyboard="false" tabIndex={-1} aria-labelledby="loan-application-label" aria-hidden="true">
    <div className="modal-dialog" style={{border:`4px solid ${themecolor1}`}}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="loan-application-label">{DashboardInfor.title}</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className='modal-icon-holder' style={{backgroundColor:`${themecolor1}`}}>
            <div className='modal-circle-icon'><img src={DashboardInfor.icon} alt='infor' />  </div>
          </div>
        
           {DashboardInfor.description}

         </div>
       
         <div className="modal-footer">
                <ButtonGroup size="small" aria-label="small outlined button group">
                 <Button  type="button"  data-bs-dismiss="modal"  color='primary'>close</Button>
               </ButtonGroup>
        </div>
      </div>
    </div>
  </div>
</div>
 



</div>
</Fragment>
 
    
);


// Dashboard.propTypes = {}
        
// Dashboard.defaultProps ={}


}
export {Dashboard};

