import React ,{Fragment,useEffect,useContext}from 'react';
import {useParams,useLocation,useHistory,Link} from "react-router-dom";
import './InboxMessageView.scss';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {UseraccountContextApi} from "../../UseraccountContextApi";
// import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import transaction_withdraw from "./transaction_withdraw.svg";
// import balance_icon from './balance_icon.svg';
// import clc_points_icon from './clc_points_icon.svg';
// import cpv_points_icon from "./cpv_points_icon.svg";
// import network_icon from "./network_icon.svg";
import {InboxSideMenu} from "../InboxNotification/InboxSideMenu";
import {MenuOpen} from '@material-ui/icons';
import {LocalAtm,Email,AddAlert} from '@material-ui/icons';
import {ServerFirstIndexedb_ActivateReadMessage} from "../../HttpcallUserApp";



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  InboxSidebarMenu:{
    [theme.breakpoints.down('sm')]:{
      display:'none'
     },
     [theme.breakpoints.up('md')]:{
      display:'block'
       
     } 
  },
}));



    
const InboxMessageView = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  const Functions = MainState.Functions

  const {Appsettings,App} = Appstate
  const {InboxsidemenuTrigger,SystemNotificationReaded,TransactionMesagesReaded} = Functions
   const {themecolor1} = Appsettings

   const {server_ip,authentication_token} = App

const classes = useStyles();


const urlParameter = useParams()

const {MessageType} = urlParameter

const Location  = useLocation()
const History = useHistory()

const {state} = Location

const [NotificationTitle, setNotificationTitle] = React.useState('READ NOTIFICATIONS');



const [MessageInstance, setMessageInstance] = React.useState({
 
      amount: 0,
      date_created: "",
      downliner_fullname: "",
      downliner_id: "",
      first_name: "",
      from_generation: 2,
      id: 157,
      last_name: "",
      message: "",
      middle_name: "",
      new_message: 1,
      sender: "",
      subtitle: "",
      subject:"",
      transaction_code: "",
      user_id: ""
});


const [NotificationType, setNotificationType] = React.useState('system_notification');
//valid inbox parameter
// 1:system_notification
//2:transactional_message
//2:personal_message





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  
if(MessageType==='system_notification' || MessageType==='transactional_message' || MessageType==='personal_message'){

    if(NotificationType !==MessageType){
      setNotificationType(MessageType)
    }
    if(MessageType==='system_notification'){
  
      setNotificationTitle('READ NOTIFICATIONS')
    }
    if(MessageType==='transactional_message'){
  
      setNotificationTitle('READ TRANSACTIONAL MESSAGES')
    }
    if(MessageType==='personal_message'){
  
      setNotificationTitle('READ PERSONAL MESSAGES')
    }
  

     if(state !==undefined){
      const {MessageInstance} = state
      const {id,new_message} = MessageInstance

      if(new_message===1){ //ensure its a new message and mark it as readed in database
         ServerFirstIndexedb_ActivateReadMessage(server_ip,id,MessageType,authentication_token).then(function(JsonResponse){
          const {status} = JsonResponse
  
          if(status===200){
              setMessageInstance(MessageInstance)


              console.log(MessageInstance)

              

              if(MessageType==='system_notification'){

                  SystemNotificationReaded()
                
              }
              if(MessageType==='transactional_message'){
                
                TransactionMesagesReaded()


              }
  
         }
  
  
        })
  

      }else{

        setMessageInstance(MessageInstance)
      }
      
  



     }else{

      History.replace({pathname:`/account/inbox/system_notification`}) 

     }




  
  }else{//NAVIGATE BACK TO INBOX

           History.replace({pathname:`/account/inbox/system_notification`}) 
  }



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])






const NavigateBack =()=>{

  History.goBack()
}





// const WithdrawReportMap = [0,1,2,3,4,5].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// })  



// const PendingTransactionMap= [0].map(function(object,index){
//   return(
//      <React.Fragment key={index}>
//     <div className="card collapsed-card">
//   <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
//     <h3 className="card-title">TSH 230000</h3>
//     <div className="card-tools">
//       <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
//       </button>
//     </div>
//     {/* /.card-tools */}
//   </div>
//   {/* /.card-header */}
//   <div className="card-body" style={{display: 'none'}}>
//     The body of the card
//   </div>
//   {/* /.card-body */}
// </div>

//   <br />
//      </React.Fragment>
//      )
// }) 



return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="inbox-message-view-wrapper">

    
<Grid container spacing={1}>


<Grid item xs={12} sm={3} className={classes.InboxSidebarMenu}>

   <InboxSideMenu />
 
</Grid>
{/* ---------SIDE BAR INBOX COLUMN ---------------*/}

{/* ---------INBOX READS ---------------*/}
<Grid item xs={12} md={9}>
<div onClick={()=>{InboxsidemenuTrigger()}} className='inbox-sidebar-menu-button' style={{backgroundColor:`${themecolor1}`}}><MenuOpen fontSize='large' style={{color:'#fff'}}/></div>

  <div className="card card-primary card-outline">
    <div className="card-header">
    <div className='inbox-notification-type' style={{backgroundColor:`${themecolor1}`}}>
       <div className='notification-type-circle'>  
        {NotificationType==='system_notification'?<AddAlert fontSize='large' style={{color:`${themecolor1}`}}/>:null}
        {NotificationType==='transactional_message'?<LocalAtm fontSize='large' style={{color:`${themecolor1}`}}/>:null}
        {NotificationType==='personal_message'?<Email fontSize='large' style={{color:`${themecolor1}`}}/>:null}
        
       </div>
      <span className='inbox-read-title'>{NotificationTitle}</span>
    </div>


      <div className="card-tools">
        <Link to="#" onClick={NavigateBack} className="btn btn-tool" title="Previous"><i className="fas fa-chevron-left" /></Link>
        <Link to="#" className="btn btn-tool" title="Next"><i className="fas fa-chevron-right" /></Link>
      </div>
    </div>
    {/* /.card-header */}
    <div className="card-body p-0">
      <div className="mailbox-read-info">
        <h5>{MessageInstance.subject}</h5>
        <h6>From: {MessageInstance.sender}
          {/* <span className="mailbox-read-time float-right">15 Feb. 2015 11:03 PM  </span> */}
          
          </h6>
      </div>
      {/* /.mailbox-read-info */}
      <div className="mailbox-controls with-border text-center">
        <div className="btn-group">
          <button type="button" className="btn btn-default btn-sm" data-container="body" title="Delete">
            <i className="far fa-trash-alt" />
          </button>
          <button type="button" className="btn btn-default btn-sm" data-container="body" title="Reply">
            <i className="fas fa-reply" />
          </button>
          <button type="button" className="btn btn-default btn-sm" data-container="body" title="Forward">
            <i className="fas fa-share" />
          </button>
        </div>
        {/* /.btn-group */}
        <button type="button" className="btn btn-default btn-sm" title="Print">
          <i className="fas fa-print" />
        </button>
      </div>
      {/* /.mailbox-controls */}
      <div className="mailbox-read-message">



        <p>{MessageInstance.message}</p>



        
      </div>
      {/* /.mailbox-read-message */}
    </div>
    {/* /.card-body */}
    {/* <div className="card-footer bg-white">
      <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
        <li>
          <span className="mailbox-attachment-icon"><i className="far fa-file-pdf" /></span>
          <div className="mailbox-attachment-info">
            <a href="#none" className="mailbox-attachment-name"><i className="fas fa-paperclip" /> Sep2014-report.pdf</a>
            <span className="mailbox-attachment-size clearfix mt-1">
              <span>1,245 KB</span>
              <a href="#none" className="btn btn-default btn-sm float-right"><i className="fas fa-cloud-download-alt" /></a>
            </span>
          </div>
        </li>
        <li>
          <span className="mailbox-attachment-icon"><i className="far fa-file-word" /></span>
          <div className="mailbox-attachment-info">
            <a href="#none" className="mailbox-attachment-name"><i className="fas fa-paperclip" /> App Description.docx</a>
            <span className="mailbox-attachment-size clearfix mt-1">
              <span>1,245 KB</span>
              <a href="#none" className="btn btn-default btn-sm float-right"><i className="fas fa-cloud-download-alt" /></a>
            </span>
          </div>
        </li>
        <li>
          <span className="mailbox-attachment-icon has-img"><img src="../../dist/img/photo1.png" alt="Attachment" /></span>
          <div className="mailbox-attachment-info">
            <a href="#none" className="mailbox-attachment-name"><i className="fas fa-camera" /> photo1.png</a>
            <span className="mailbox-attachment-size clearfix mt-1">
              <span>2.67 MB</span>
              <a href="#none" className="btn btn-default btn-sm float-right"><i className="fas fa-cloud-download-alt" /></a>
            </span>
          </div>
        </li>
        <li>
          <span className="mailbox-attachment-icon has-img"><img src="../../dist/img/photo2.png" alt="Attachment" /></span>
          <div className="mailbox-attachment-info">
            <a href="#none" className="mailbox-attachment-name"><i className="fas fa-camera" /> photo2.png</a>
            <span className="mailbox-attachment-size clearfix mt-1">
              <span>1.9 MB</span>
              <a href="#none" className="btn btn-default btn-sm float-right"><i className="fas fa-cloud-download-alt" /></a>
            </span>
          </div>
        </li>
      </ul>
    </div> */}
    {/* /.card-footer */}


    
    <div className="card-footer">
      <div className="float-right">
        <button type="button" className="btn btn-default"><i className="fas fa-reply" /> Reply</button>
        <button type="button" className="btn btn-default"><i className="fas fa-share" /> Forward</button>
      </div>
      <button type="button" className="btn btn-default"><i className="far fa-trash-alt" /> Delete</button>
      <button type="button" className="btn btn-default"><i className="fas fa-print" /> Print</button>
    </div>
    {/* /.card-footer */}
  </div>

</Grid>




</Grid>





</div>
</Fragment>
 
    
);


// InboxMessageView.propTypes = {}
        
// InboxMessageView.defaultProps ={}


}
export {InboxMessageView};

