
  
const HttpServer_UserRegistration=(ServerIp,Form,keys='3DDDFRTFDD644')=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  'Authorization': `{"auth_token":"${keys}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/user_registration`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200 || status===202){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
 
    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}


  
const HttpServer_AddSupperAccount =(ServerIp,Form,keys='3DDDFRTFDD644')=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${keys}"}`, 
  } 
  
  fetch(`${ServerIp}api/adminapp/add_supperaccount`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse = JSON.parse(TextResponse)
  if(status === 200){ //-----SUCCESS RESPONSE
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    
    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}






  
const HttpServer_FetchUserProfileInformation =(ServerIp,UserName,keys='3DDDFRTFDD644')=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  'Authorization': `{"auth_token":"${keys}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/user_profile_information/${UserName}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse = JSON.parse(TextResponse)
  if(status === 200){ //-----SUCCESS RESPONSE
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    
    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}




const HttpServer_UserLogin = (ServerIp,Form,keys='3DDDFRTFDD644')=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  'Authorization': `{"auth_token":"${keys}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/user_login`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:JSON.parse(TextResponse)})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}



const ServerFirstIndexedb_ApplicationMounting = (ServerIp,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/application_mounting/${authentication_token}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}








const ServerFirstIndexedb_FetchGeneration = (ServerIp,authentication_token,generation='distributor',pagination_start,pagination_end)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/fetch_generation/${authentication_token}/${generation}/${pagination_start}/${pagination_end}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}










const ServerFirstIndexedb_FetchUserProfile = (ServerIp,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/fetch_user_profile/${authentication_token}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}




  
const HttpServer_UpdateProfileFormData=(ServerIp,FormData,profile_completed,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/update_profile_field/${authentication_token}/${profile_completed}`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(FormData),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}



const HttpServer_UpdateProfilePhoto=(ServerIp,ProfileImage,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/update_profile_photo/${authentication_token}`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(ProfileImage),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}














const ServerFirstIndexedb_FetchInboxMessages = (ServerIp,inbox_type,pagination_start=0,pagination_end=10,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/fetch_inbox_messages/${authentication_token}/${inbox_type}/${pagination_start}/${pagination_end}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

    status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}





const ServerFirstIndexedb_ActivateReadMessage =(ServerIp,message_id,message_type,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/activate_read_message/${authentication_token}/${message_type}/${message_id}`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify({}),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}







const ServerFirstIndexedb_TransactionWithdrawRequest =(ServerIp,FormData,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/transaction_withdraw_request/${authentication_token}`,
  {method:'POST', 
  headers: Headers,
  body:JSON.stringify(FormData),
  
  }).then(function(Response){ 

  status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{
    JsonResponse  = JSON.parse(TextResponse)

    return  resolve({status:status,Jsondata:JsonResponse})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}











const ServerFirstIndexedb_FetchTransactionalAccount = (ServerIp,authentication_token)=>{
  return new Promise(function(resolve,reject){
  var status = undefined 
  var Headers =  {
  'accept': 'application/json',
  'Content-Type':'application/x-www-form-urlencoded',
  // 'Authorization': `{"auth_token":"${authentication_token}"}`, 
  } 
  
  fetch(`${ServerIp}api/userapp/fetch_transactional_account/${authentication_token}`,
  {method:'GET', 
  headers: Headers,
  // body:JSON.stringify(Form),
  
  }).then(function(Response){ 

    status = Response.status
  
  return Response.text()
  }).then(function(TextResponse){ 
  var JsonResponse 
  if(status === 200){ //-----SUCCESS RESPONSE
    JsonResponse  = JSON.parse(TextResponse)
     
  
    return  resolve({status:status,Jsondata:JsonResponse})
  
   }else{

    return  resolve({status:status,Jsondata:{}})
   }
  }).catch(function(error){ //--------------CATCH ALL THE ERROR
    
    console.log(error)

    return  reject({authentication:{token:"none",user_exist:"false",authenticated:"false"}})
})  
})
}














export{
HttpServer_UserRegistration,
HttpServer_AddSupperAccount,
HttpServer_FetchUserProfileInformation,
HttpServer_UserLogin,
ServerFirstIndexedb_ApplicationMounting,
ServerFirstIndexedb_FetchGeneration,
ServerFirstIndexedb_FetchUserProfile,
HttpServer_UpdateProfileFormData,
HttpServer_UpdateProfilePhoto,
ServerFirstIndexedb_FetchInboxMessages,
ServerFirstIndexedb_ActivateReadMessage,
ServerFirstIndexedb_TransactionWithdrawRequest,
ServerFirstIndexedb_FetchTransactionalAccount

}


