import React ,{useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {RouteNavigation} from "./RouteNavigation";
// import $ from 'jquery';
// import Cookies from 'js-cookie';


function App(props){ //-----------MAIN APP

useEffect(()=>{///<<<<<<---------COMPONENT DID MOUNT-------------------------------------


    



return ()=>{//<<<-------------- COMPONENT WILL UNMOUNT----





}

},[])





 
return( ///<<<<<<<<<-------------------Returning Main App---------------------
<div className='App'>










<RouteNavigation /> 


  

</div>
)
}
export default withRouter(App)//To enable url fetching
