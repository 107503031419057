import React ,{Fragment,useEffect}from 'react';
import {useHistory,useLocation} from "react-router-dom";
import './LoginPage.scss';
//import { makeStyles } from '@material-ui/core/styles';
import {ThemeProvider,createMuiTheme} from "@material-ui/core";
import {Grid,Divider,Typography,Button,ButtonGroup} from '@material-ui/core';   
import Cookies from 'js-cookie';
import {CheckCircle,CancelPresentation} from '@material-ui/icons';
import Container from '@material-ui/core/Container';
import {HttpServer_UserLogin} from "../../Useracccount/HttpcallUserApp";
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';

import {ScaleLoader} from "react-spinners";





// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));


    
const LoginPage = ({props}) => { //--------------MAIN FUNCTION----------------

//const classes = useStyles();


const [Formsettings, setFormsettings] = React.useState({
  logo:'brand-logo.png',
  themecolor1:'#FBB117',
  themecolor2:'#CA226B',
});



const theme = createMuiTheme({

  palette:{
    primary:{
      main:`${Formsettings.themecolor1}`
    },
    secondary:{
      main:`${Formsettings.themecolor2}`
    },
  },
  typography:{
    //fontFamily:'Comic Sans Ms',
    body1:{
      fontSize:'1.1rem',
    
    },
    // h1:{
    //   color:'red'
    // }
  },
  shape:{
    borderRadius:0,
  },
  //spacing:8,
  overrides:{
    MuiButton:{
      root:{
        textTransform:'none',
        
      }
    },

  },
  props:{
    MuiButton:{
      // disableRipple:true,
      // variant:'text'
    },
    MuiButtonGroup:{
      // disableRipple:true,
      // variant:'outlined'
    },
    MuiTextField:{
      InputLabelProps:{
        shrink:true
      }
    }
    
  }

})


const History = useHistory()
const Location = useLocation()

const {state} = Location


const [DemoUsernameCount, setDemoUsernameCount] = React.useState(1);

const [LogoutMessage, setLogoutMessage] = React.useState(null);


const [RegistrationForm, setRegistrationForm] = React.useState({
  username_email:'',
  password:'',
  keepme_loggedin:false,

}
  );  


const [FormValidationError, setFormValidationError] = React.useState({
    FirstNameError:false,
    LastNameError:false,
    MiddleNameError:false,
    PhoneNumber1Error:false,
    PhoneNumber2Error:false,
    RegionNameError:false,
    DistrictNameError:false,
    WardNameError:false,
    SponsoridError:false,
    VoucherError:false,
    PrimaryEmailError:false,
    UsernameEmailError:false,
    PasswordError:false,
    PasswordMatchError:false,
    InheritanceFirstnameError:false,
    InheritanceLastNameError:false,
    InheritanceMiddlenameError:false,
    InheritanceBanknameError:false,
    InheritanceBankAccountNameError:false,
    InheritanceBankNumberError:false,
    InheritanceRelationshipError:false,
    InheritancePhoneNumberError:false,
    }
);

const [ServerStatusResponse, setServerStatusResponse] = React.useState(null);
 
const [ServerMessage, setServerMessage] = React.useState(null);

const [RegistrationLoading, setRegistrationLoading] = React.useState(false);


const [ServerIpAddress, setServerIpAddress] = React.useState(()=>{
  if(process.env.NODE_ENV==='production'){
    return "/";
   }else{ 
     return  "http://127.0.0.1:8000/";
    }
});





useEffect(()=>{//------------COMPONENT DID MOUNT--------------------------------------
  


  if(state !==undefined){

    const {message} = state

    setLogoutMessage(message)
  }
 

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])





const OnchangeUsernameEmail =(event)=>{

  const {value} = event.target
  setRegistrationForm({...RegistrationForm,username_email:value})
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,SponsoridError:false})
  
  }
} 

const OnchangePasword =(event)=>{
  
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,password:value})

  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,PasswordError:false})
  
  }
} 






const FormValidation = () =>{
  return new Promise(function(resolve,reject){

   if(RegistrationForm.username_email.length < 5){
   
    setFormValidationError({...FormValidationError,UsernameEmailError:true})
    return reject('username or emails is too short')

   }else if(RegistrationForm.password.length < 4){

    setFormValidationError({...FormValidationError,PasswordMatchError:true})

   }else{

    return resolve('successfuly validation')
   }


  })
}






const OnsubmitLoginFunction = ()=>{
//---validate form first
FormValidation().then(function(JsonResponse){

  setServerStatusResponse(null)
  var FormPayload = RegistrationForm
  setRegistrationLoading(true)

  HttpServer_UserLogin(ServerIpAddress,FormPayload).then(function(JsonResponse){
  const {status,Jsondata} = JsonResponse
  setRegistrationLoading(false)

  console.log(Jsondata)

  if(status===200){//-----SUCCESS AUTHENTICATION---
    const {token}  = Jsondata
    
       //Cookies.set('name', 'value', { expires: 7, path: '' });
       //Create a cookie that expires 7 days from now, valid across the entire site:
        Cookies.set("USR-IDD",token, { expires: RegistrationForm.keepme_loggedin ===true?7:1, path: '/' });
       //History.replace({pathname:"/adminpanel",state:{autheticated:true}})  
 
       var LoginRefreshPage =  $('#admin-login-refresh')
       LoginRefreshPage[0].click()
 

       setServerStatusResponse(status)
      //setServerMessage(message)

    

   }else{
   
    const {message} = Jsondata
    setServerStatusResponse(status)
    setServerMessage(message)
    
}

}).catch(function(ErrorResponse){

     setRegistrationLoading(false)
})

}).catch(function(ValidationErrorMesage){

  

})


}


const OnchangeKeepmeLogin =(event)=>{

  setRegistrationForm({...RegistrationForm,keepme_loggedin:event.target.checked})

} 





const NavigateRegistration =()=>{

  History.replace({pathname:"/auth/registration"})
}


  
  







return (   //--------RETURN HTML---------------------------
<Fragment>
<ThemeProvider theme={theme}>   

<div className="container">
  <div className="row g-2">
    <div className="col-12">
       <a href='/dashboard' id='admin-login-refresh'></a>
         <Container maxWidth='sm'>
             <div className='nav-padding'></div>

                 {LogoutMessage !==null?
                 <div className="callout callout-danger">
                  <h5>Your session has expired !</h5>

                  <p>{LogoutMessage}</p>
                </div>
                :null}

    <div className="profile-wrapper">


<div className='registration-top-nav'></div>
<div className="card" id='registration-form-card'>
    <div className="card-body" style={{opacity:RegistrationLoading===true?'0.7':null}}> 
     
     
     
       <div className='company-logo-container'>
           <div class='company-logo-circle' style={{border:`4px solid ${Formsettings.themecolor1}`}}>
                    
                    <img src={Formsettings.logo} alt='brand logo'/>
              </div>
      
          </div>
          <br />
          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Username OR Email"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeUsernameEmail} value={RegistrationForm.username_email}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.username_email.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.UsernameEmailError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
              <Grid item xs={10}>
                {FormValidationError.UsernameEmailError===true?
                <div className="validation-error-message">
                <span>Username or email too short </span>
                </div>
                :null}
              </Grid>
           </Grid>
           <Divider />
           <br/>

           <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Password"
              type='password'
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePasword} value={RegistrationForm.password}
              disabled={RegistrationLoading===true?true:false} 
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.password > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.PasswordError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
           </Grid>
           <Divider />
           <br/>


           <Grid container spacing={0}> 
            <Grid item xs={11} sm={12}>

            <div className="icheck-primary d-inline">
            <input type="checkbox" id="checkboxPrimary3"  onClick={OnchangeKeepmeLogin}/>
            <label htmlFor="checkboxPrimary3">
               Keepme login
            </label>
             </div>
        
            </Grid>
           </Grid>
           <Divider />

      
               {ServerStatusResponse !==200 && ServerStatusResponse !==null?
                <div className="alert alert-warning alert-dismissible">
                 <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                   <h5><i className="icon fas fa-exclamation-triangle" /> Alert!</h5>
                   {ServerStatusResponse >= 400?<span>

                     ServerError {ServerStatusResponse
                     }
                   </span>:null} {ServerMessage}
                </div>
                :null}
                
                {ServerStatusResponse===200?
               <div className="alert alert-success alert-dismissible" id='authentication-success'>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                 <h5><i className="icon fas fa-check" /></h5>
                   <span>{ServerMessage}</span>
                </div>
                :null}

            

           </div>
               <div className='registration-loader-wrapper'>
                <ScaleLoader speedMultiplier={2} loading={RegistrationLoading} height={50} width={10} color={Formsettings.themecolor1} />
                </div>
                


     
               <ButtonGroup fullWidth={true}  color='secondary' aria-label="small outlined button group">
                 <Button onClick={NavigateRegistration} disabled={RegistrationLoading===true?true:false}   type="button"  data-bs-dismiss="modal"  color='primary'>Register</Button>
                 <Button disabled={RegistrationLoading===true?true:false}   onClick={OnsubmitLoginFunction} type="button"  data-bs-dismiss="modal"  color='primary'>Login</Button>
               
               </ButtonGroup>

               {/* <ButtonGroup fullWidth={true}  color='secondary' aria-label="small outlined button group">
                 <Button  onClick={UserProfileInformation} type="button"  data-bs-dismiss="modal"  color='primary'>PROFILE INFO</Button>
               </ButtonGroup> */}

               <div className="registration-form-footer">
              
                </div>
      </div>




</div>



      </Container>
      </div>
  </div>
</div>

</ThemeProvider>
</Fragment>
 
    
);


// LoginPage.propTypes = {}
        
// LoginPage.defaultProps ={}


}
export {LoginPage};

