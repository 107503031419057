import React ,{Fragment,useEffect}from 'react';
//import {Link} from "react-router-dom";
import './SuperAccountRegistration.scss';
//import { makeStyles } from '@material-ui/core/styles';
import {ThemeProvider,createMuiTheme} from "@material-ui/core";
//import Box from '@material-ui/core/Box';
import {Grid,Divider,Typography,Button,ButtonGroup} from '@material-ui/core';   
// import {TanzaniaRegionsList,TanzaniaDistrictList} from "../../../TanzaniaRegionList";
import {CheckCircle,CancelPresentation} from '@material-ui/icons';
import {HttpServer_AddSupperAccount} from "../../Useracccount/HttpcallUserApp";
import Container from '@material-ui/core/Container';
//import $ from 'jquery';
import TextField from '@material-ui/core/TextField';






// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));


    
const SuperAccountRegistration = ({props}) => { //--------------MAIN FUNCTION----------------

//const classes = useStyles();

const [Formsettings, setFormsettings] = React.useState({
  logo:'logo_avatar.png',
  themecolor1:'#FBB117',
  themecolor2:'#CA226B',
});



const theme = createMuiTheme({

  palette:{
    primary:{
      main:`${Formsettings.themecolor1}`
    },
    secondary:{
      main:`${Formsettings.themecolor2}`
    },
  },
  typography:{
    //fontFamily:'Comic Sans Ms',
    body1:{
      fontSize:'1.1rem',
    
    },
    // h1:{
    //   color:'red'
    // }
  },
  shape:{
    borderRadius:0,
  },
  //spacing:8,
  overrides:{
    MuiButton:{
      root:{
        textTransform:'none',
        
      }
    },

  },
  props:{
    MuiButton:{
      // disableRipple:true,
      // variant:'text'
    },
    MuiButtonGroup:{
      // disableRipple:true,
      // variant:'outlined'
    },
    MuiTextField:{
      InputLabelProps:{
        shrink:true
      }
    }
    
  }

})


const [RegistrationForm, setRegistrationForm] = React.useState({
  username_email:'',
  password:'williamjs.2020',
  confirm_password:'',
  network_letter:'A',

}
  );  


  const [ServerIpAddress, setServerIpAddress] = React.useState(()=>{
    if(process.env.NODE_ENV==='production'){
      return "/";
     }else{ 
       return  "http://127.0.0.1:8000/";
      }
  });
  

const [FormValidationError, setFormValidationError] = React.useState({
    FirstNameError:false,
    LastNameError:false,
    MiddleNameError:false,
    PhoneNumber1Error:false,
    PhoneNumber2Error:false,
    RegionNameError:false,
    DistrictNameError:false,
    WardNameError:false,
    SponsoridError:false,
    VoucherError:false,
    PrimaryEmailError:false,
    UsernameEmailError:false,
    PasswordError:false,
    ConfirmPasswordError:false,
    InheritanceFirstnameError:false,
    InheritanceLastNameError:false,
    InheritanceMiddlenameError:false,
    InheritanceBanknameError:false,
    InheritanceBankAccountNameError:false,
    InheritanceBankNumberError:false,
    InheritanceRelationshipError:false,
    InheritancePhoneNumberError:false,
    }
);

const [ServerStatusResponse, setServerStatusResponse] = React.useState(null);
 
const [ServerMessage, setServerMessage] = React.useState(null);




useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  



//0683300300 msangwa herbal kona ya nyamhongoro



return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])







  












  




const OnchangeUsernameEmail =(event)=>{

  const {value} = event.target
  setRegistrationForm({...RegistrationForm,username_email:value})
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,SponsoridError:false})
  
  }
} 

const OnchangePasword =(event)=>{
  
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,password:value})

  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,PasswordError:false})
  
  }
} 

const OnchangeConfirmPassword =(event)=>{
  
  const {value} = event.target
  setRegistrationForm({...RegistrationForm,confirm_password:value})
  
  if(value.length >= 3){
  
    setFormValidationError({...FormValidationError,ConfirmPasswordError:false})
  
  }
} 


const OnchangeNetworkLetter =(event)=>{

  setRegistrationForm({...RegistrationForm,network_letter:event.target.value})
  
 
  }




const FormValidationFunction = ()=>{
var FormPayload = RegistrationForm

  HttpServer_AddSupperAccount(ServerIpAddress,FormPayload).then(function(JsonResponse){

  const {status,Jsondata} = JsonResponse
  console.log(JsonResponse)
  if(status===200){
    const {message} = Jsondata

    setServerStatusResponse(status)
    setServerMessage(message)

   



  }else{


    const {message} = Jsondata
    setServerStatusResponse(status)
    setServerMessage(message)
    
}

})



}


  





return (   //--------RETURN HTML---------------------------
<Fragment>
<ThemeProvider theme={theme}>   




<div className="container">
  <div className="row g-2">
    <div className="col-12">

      <Container maxWidth='sm'>

      <div className="addsuper-account-wrapper">


<div className='registration-top-nav'></div>
<div className="card">
    <div className="card-body"> 

    <div className='addsuper-acccount-title'>
      <Typography variant='h6'>ADD SUPPER ACCOUNT</Typography>
    </div>
     
     
     
       <div className='company-logo-container'>
           <div class='company-logo-circle' style={{border:`4px solid ${Formsettings.themecolor1}`}}>
                    
                    <img src={Formsettings.logo} alt='brand logo'/>
              </div>
      
          </div>
          <br />

  
  
        
          <Typography variant='caption'>---------PERSONAL INFORMATION---------</Typography>
          <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
               <TextField
              id="filled-required"
              label="Username OR Email"
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeUsernameEmail} value={RegistrationForm.username_email}
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.username_email.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.UsernameEmailError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
           </Grid>
           <Divider />
           <br/>

           <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Password"
              type='password'
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangePasword} value={RegistrationForm.password}
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.password > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.PasswordError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
           </Grid>
           <Divider />
           <br/>

           <Grid container spacing={0}> 
            <Grid item xs={11} sm={10}>
              <TextField
              id="filled-required"
              label="Confirm Password"
              type='password'
              variant="filled"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              onChange={OnchangeConfirmPassword} value={RegistrationForm.confirm_password}
              />
            </Grid>

            <Grid item xs={1}>
              <div className="registration-validation">
                  {RegistrationForm.confirm_password.length > 5?<CheckCircle fontSize="default" className="validation-success"/>:null}
                 {FormValidationError.ConfirmPasswordError ===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
              </div>
              </Grid>
           </Grid>
           <Divider />
           <br/>

           <Grid container spacing={0}>
                 <Grid item xs={11} sm={10}>
                 <div className="form-group">
                    <label htmlFor="exampleFormControlSelect4">SELECT GENERATION LETTER</label>
                      <select onChange={OnchangeNetworkLetter}  value={RegistrationForm.region_name} className="form-control" id="exampleFormControlSelect4">
    
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                      <option value="F">F</option>
                      <option value="G">G</option>
                      <option value="H">H</option>
                      <option value="I">I</option>
                      <option value="J">J</option>
                      <option value="K">K</option>
                      <option value="L">L</option>
                      <option value="M">M</option>
                      <option value="N">N</option>
                      <option value="O">O</option>
                      <option value="P">P</option>
                      <option value="Q">Q</option>
                      <option value="R">R</option>
                      <option value="S">S</option>
                      <option value="T">T</option>
                      <option value="U">U</option>
                      <option value="V">V</option>
                      <option value="W">W</option>
                      <option value="X">X</option>
                      <option value="Y">Y</option>
                      <option value="Z">Z</option>

                     
                     </select>
                  </div>

                </Grid>
              
               <Grid item xs={1}>
                 <div className="registration-validation">
              {RegistrationForm.region_name !=="0"?<CheckCircle fontSize="default" className="validation-success"/>:null}    
              {FormValidationError.RegionNameError===true?<CancelPresentation fontSize="default" className="validation-error"/>:null}
               
                 </div>
                 </Grid>
                </Grid>





                 {ServerStatusResponse !==null && ServerStatusResponse !==200?
                <div className="alert alert-warning alert-dismissible">
                 <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                   <h5><i className="icon fas fa-exclamation-triangle" /> Alert!</h5>
                  {ServerMessage}
                </div>
                :null}
                
                {ServerStatusResponse===200?
               <div className="alert alert-success alert-dismissible">
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                 <h5><i className="icon fas fa-check" /> Alert!</h5>
                   {ServerMessage}
                </div>
                :null}




           </div>


     
              <ButtonGroup fullWidth={true}  color='secondary' aria-label="small outlined button group">
                 <Button  onClick={FormValidationFunction} type="button"  data-bs-dismiss="modal"  color='primary'>Save change</Button>
               </ButtonGroup>
      </div>




</div>


    

      </Container>
      




    </div>
  </div>
</div>





</ThemeProvider>
</Fragment>
 
    
);


SuperAccountRegistration.propTypes = {}
        
SuperAccountRegistration.defaultProps ={}


}
export {SuperAccountRegistration};

