import React ,{Fragment,useEffect}from 'react';
import {Route,Switch} from 'react-router-dom';
import {Landingpage} from './Components/Landingpage/Landingpage';
import {Useraccount} from "./Components/Useracccount/Useraccount";
import {UserRegistration} from "./Components/Landingpage/UserRegistration/UserRegistration";
import {SuperAccountRegistration} from "./Components/Landingpage/SuperAccountRegistration/SuperAccountRegistration";
import {LoginPage} from "./Components/Landingpage/LoginPage/LoginPage";




const  RouteNavigation = (props) => {
//var Location = useLocation() 
//var AppUrl = Location.pathname.split('/')


// const [Appstate,setAppstate] = useState(
//   {authenticated:(function(){
//       var token = Cookies.get("CUID_XXX_SESSION")
//       if(token !==undefined){
//       return true
//       }else{
//       return false
//       }   
//       })(),
//       token:(function(){
//         var token = Cookies.get("CUID_XXX_SESSION")
//         if(token !==null && token !==undefined){
//         return token
//         }else{
//         return null
//         }   
//       })(),
//       cart_item_count:(function(){
//         var ShoppingCart = localStorage.getItem("shopping_cart")
//         if(ShoppingCart !==null){
//         var JsonData = JSON.parse(ShoppingCart)  
//         return Object.keys(JsonData).length
//         }else{
//         return 0
//         }   
//         })(),
//       wishlist_item_count:(function(){
//           var WishList = localStorage.getItem("wishlist_items")
//           if(WishList !==null && WishList !==undefined){
    
//           var Items = JSON.parse(WishList)  
    
    
//           return Object.keys(Items).length
//           }else{
//           return 0
//           }   
//           })(),
//         server_ip:(function(){
//           if(process.env.NODE_ENV==='production'){
//            return "/"
//           }else{ 
//             return  "http://127.0.0.1:8000/"
//            }
      
//         })(),
//         service_worker:(function(){
//           var service_worker = localStorage.getItem('service_worker')
//           if(service_worker !==null && service_worker !==undefined){
//             if(service_worker==='true'){

//               return true
//             }else{
//               return false
//             }
//           }else{ return false}   
//         })(),
//         indexedb_created:(function(){
//           var indexedb = localStorage.getItem('indexedb')
//           if(indexedb ==='true'){
         
//             return true
//           }else{ return false}   
//         })(),
//         db_version:1
//   })










useEffect(()=>{//COMPONENT DID MOUNT






 
    


},[])




  




// const BackdropLoadingComponent = ()=>{

// return(
// <React.Fragment>
//       <Backdrop className={classes.backdrop} open={open}>
//         {siteNotFound===false && InternetError===false?<CircularProgress color="inherit" />:null}
//         {siteNotFound===false && InternetError===false?<span>Loading...</span>:null}
//         {siteNotFound===true?<span>site not found...</span>:null}  
//         {InternetError===true?<span>could not contact server!</span>:null} 
//       </Backdrop>
//     </React.Fragment>
// )

// }  


  









return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>
<Switch>


<Route exact path='/auth/login' render={(props)=>(<LoginPage />)} /> 

<Route  path='/auth/registration' render={(props)=>(<UserRegistration />)} /> 

<Route  path='/adminpanel/add_supperaccount' render={(props)=>(<SuperAccountRegistration />)} /> 

<Route  path='/' render={(props)=>(<Useraccount />)} /> 


<Route  path='/' render={(props)=>(<Landingpage />) }/>

<Route render={(props)=>(<h1>PAGE NOT FOUND !</h1> )}  /> 

</Switch>

</Fragment>
);
}
export {RouteNavigation};

