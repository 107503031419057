import React ,{Fragment,useEffect}from 'react';




    
const Landingpage = ({props}) => { //--------------MAIN FUNCTION----------------




useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  







return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}



},[])



 


return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="landing-page">


    <h1>Fermsworldtz business profile</h1>
      <br />
      <span>on construction.....</span>



</div>
 
</Fragment>
 
    
);


// Landingpage.propTypes = {}
        
// Landingpage.defaultProps ={}


}
export {Landingpage};

