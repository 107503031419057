import React ,{Fragment,useEffect,useContext}from 'react';
import './RewardSection.scss';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
//import {Money,History,LocalAtm,AccountBalance} from '@material-ui/icons';
import {UseraccountContextApi} from "../../UseraccountContextApi";

//import {DashboardCard,DashboardCardCircle,DashboardCardLogo,DashboardCardText} from "../StyledComponents/styledcomponents";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));



    
const RewardSection = ({props}) => { //--------------MAIN FUNCTION----------------

  const MainState = useContext(UseraccountContextApi)
  const Appstate = MainState.Appstate
  //const Functions = MainState.Functions

  const {Appsettings} = Appstate
  //const {CurrencyFormat} = Functions
   const {themecolor1,themecolor2} = Appsettings

   //const classes = useStyles();



// const [ErrorMessage, setErrorMessage] = React.useState(null);

// const [DashboardInfor, setDashboardInfor] = React.useState({
//   title:'',
//   description:'',
//   icon:'',
// });

// const [ModalTitle, setModalTitle] = React.useState('');





useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------
  







return()=>{//<<<<<<<<<<<----------------Component Wiil Unmo




   

}
},[])







const WithdrawReportMap = [0,1,2,3,4,5].map(function(object,index){
  return(
     <React.Fragment key={index}>
    <div className="card collapsed-card">
  <div className="card-header" style={{backgroundColor:`${themecolor1}`}}>
    <h3 className="card-title">TSH 230000</h3>
    <div className="card-tools">
      <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-plus" />
      </button>
    </div>
    {/* /.card-tools */}
  </div>
  {/* /.card-header */}
  <div className="card-body" style={{display: 'none'}}>
    The body of the card
  </div>
  {/* /.card-body */}
</div>

  <br />
     </React.Fragment>
     )
})  



const RewardListMap = [0,1,2,3,4].map(function(object,index){
  return(
     <React.Fragment key={index}>
       <Grid item xs={12}>
       <div className='reward-card'>
    <div className='reward-image'><div className='reward-provider-logo'></div> <img src='1.png' alt='reward product'/></div>
    <div className='reward-text'>
      <ul style={{color:`${themecolor2}`}}>
        <li><b>Iphone tablets</b></li>
        <Divider />
        <li><b>Value</b>  TSH 1,500000</li>
        <Divider />
        <li><b>status</b><span class="badge bg-warning">pending</span></li>
        <Divider />
      </ul>
      <span className='reward-description'>You get an iphone tablet worth TSH 1,50000 which accompanied by luxurious trip and cover expenses</span>
    
    </div>

  </div>
  </Grid>
     </React.Fragment>
     )
}) 



return (   //--------RETURN HTML---------------------------
<Fragment>
<div className="reward-section-wrapper" style={{width:260}}>
<Grid container spacing={1}>
{/* 
<Grid item xs={12} sm={4}>
<div className='reward-badge-section' style={{backgroundColor:`${themecolor1}`}}>
    <div className='reward-badge-circle'></div>
  
    <span>SILVER</span>

  </div>

</Grid> */}


 {/* {RewardListMap} */}
 <span>REWARDS ON PROGRESSION.......</span>


 

  
</Grid>
</div>
</Fragment>
 
    
);


// RewardSection.propTypes = {}
        
// RewardSection.defaultProps ={}


}
export {RewardSection};

